import { AuthContext } from "contexts/AuthContext";
import { useContext } from "react";
import { useHistory } from "react-router";
import { LOGIN_ROUTE, RECIPES_ROUTE } from "utils/routes";
import ExploreRecipesComponent from "./ExploreRecipes";

const ExploreRecipes = () => {
  const history = useHistory();
  const { user } = useContext(AuthContext);

  return (
    <ExploreRecipesComponent
      text="Acceso exclusivo a recetario con más
    de 700 recetas combinadas 
    especialmente para ti"
      button={{
        action: () => {
          if (user) {
            history.push(RECIPES_ROUTE);
          } else {
            history.push(LOGIN_ROUTE);
          }
        },
        text: "Explorar recetas",
      }}
    />
  );
};

export default ExploreRecipes;
