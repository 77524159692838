import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";

import Header from "components/Header";
import { ComponentContext } from "utils/types";
import {
  HOME_ROUTE,
  RECIPES_ROUTE,
  ABOUT_US_ROUTE,
  CONTACT_ROUTE,
  LOGIN_ROUTE,
  NEW_RECIPE_ROUTE,
  USER_ROUTE,
  USERS_ROUTE,
  MENU_ROUTE,
} from "utils/routes";

import logo from "assets/logo.png";
import { AuthContext } from "contexts/AuthContext";

const initialItems = [
  {
    link: HOME_ROUTE,
    text: "Inicio",
  },
  {
    link: RECIPES_ROUTE,
    text: "Recetario",
  },
  {
    link: ABOUT_US_ROUTE,
    text: "Quiénes somos",
  },
  {
    link: CONTACT_ROUTE,
    text: "Contacto",
  },
  {
    link: MENU_ROUTE,
    text: "Menu",
  },
];

const AppContainer: FunctionComponent = ({ children }) => {
  const { user, logout } = useContext(AuthContext);
  const [items, setItems] = useState([
    ...initialItems,
    {
      link: user ? USER_ROUTE : LOGIN_ROUTE,
      text: user ? "Logout" : "Ingresar",
      action: logout,
      context: ComponentContext.DEFAULT,
    },
  ]);

  useEffect(() => {
    if ((user && user.role === "user") || !user)
      setItems([
        ...initialItems,
        {
          link: user ? USER_ROUTE : LOGIN_ROUTE,
          text: user ? "Logout" : "Ingresar",
          action: user ? logout : () => {},
          context: ComponentContext.DEFAULT,
        },
      ]);
    else if (user && user.role === "admin") {
      setItems([
        ...initialItems,
        {
          link: USERS_ROUTE,
          text: "Pacientes",
        },
        {
          link: NEW_RECIPE_ROUTE,
          text: "Nueva receta",
        },
        {
          link: user ? USER_ROUTE : LOGIN_ROUTE,
          text: user ? "Logout" : "Ingresar",
          action: user ? logout : () => {},
          context: ComponentContext.DEFAULT,
        },
      ]);
    }
  }, [user, logout]);

  return (
    <>
      <Header logo={logo} items={items} />
      <div style={{ marginTop: 83 }}>{children}</div>
    </>
  );
};

export default AppContainer;
