import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeType) => ({
  container: {
    background: theme.palette.primary.light,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },

  buttonsContainer: {
    display: "flex",
    width: 413,
    justifyContent: "space-between",
  },

  web: {
    display: 'flex',
    flexDirection: 'column',
  },

  mobile: {
    display: "none",
  },

  cardContainer: {
    width: 300,
    maxWidth: 300,
    padding: 15,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },

  "@media (max-width: 750px)": {
    container: {
      paddingTop: 43,
      paddingBottom: 43,
      '& span': {
        marginBottom: 20,
      }
    },

    web: {
      display: "none",
    },

    mobile: {
      display: "flex",
      flexDirection: 'column',
      alignItems: 'center',
    },
  },

  "@media (max-width: 380px)": {
    cardContainer: {
      maxWidth: 240,
    },
  },
}));

export default useStyles;
