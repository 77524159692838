import { combineReducers } from "@reduxjs/toolkit";

import { reducer as counter } from "./Counter";
import { reducer as recipes } from "./Recipes";
import { reducer as users } from "./Users";

const rootReducer = combineReducers({
  counter,
  recipes,
  users
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
