import Button from "components/common/Button";
import Text from "components/common/Text";
import React, { FunctionComponent, ReactNode } from "react";
import { ComponentContext } from "utils/types";

import useStyles from "./styles";
import triangle from 'assets/triangle.png';

interface IntroProps {
  title: string | ReactNode;
  subtitle: string;
  button?: { text: string; action: () => void };
  image?: string;
}

const Intro: FunctionComponent<IntroProps> = ({
  title,
  subtitle,
  button,
  image,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.data}>
        <Text type="h1" weight="700" margin="0px 0px 20px 0px">
          {title}
        </Text>
              <Text type="h2" weight="400" margin="0px 0px 20px 0px">
          {subtitle}
        </Text>
        {button && (
          <Button
            margin={0}
            onClick={button.action}
            text={button.text}
            context={ComponentContext.SECONDARY}
          />
        )}
      </div>
      {image && (
        <>
        <img className={classes.triangle} src={triangle} alt="bottom right" />
        <img className={classes.image} src={image} alt="bottom right" />
        </>
      )}
    </div>
  );
};

export default Intro;
