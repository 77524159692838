import Button from "components/common/Button";
import { FunctionComponent, useState } from "react";
import Card from "./Card";
import { useHistory } from "react-router";
import { ComponentContext } from "utils/types";
import { NEW_USER_ROUTE, USERS_ROUTE } from "utils/routes";
import searchIcon from "assets/searchIcon.png";

import useStyles from "./styles";
import InputIcon from "components/common/InputIcon";

interface ContentProps {
  users: UserType[];
}

const Content: FunctionComponent<ContentProps> = ({ children, users }) => {
  const [search, setSearch] = useState("");
  const history = useHistory();
  const classes = useStyles();

  const renderCards = () => {
    return users
      .filter((user) =>
        user.displayName.toUpperCase().includes(search.toUpperCase())
      )
      .map((user: UserType) => (
        <Card
          onClick={() => {
            history.push(`${USERS_ROUTE}/${user.id}`);
          }}
          key={user.id}
          margin={`15px 0px 15px 0px`}
          cursor="pointer"
          title="titulo"
        >
          <p>{user.displayName}</p>
          <p>{user.email}</p>
          <p>{user.role}</p>
        </Card>
      ));
  };

  const goToNewUser = () => {
    history.push(NEW_USER_ROUTE);
  };

  return (
    <div className={classes.container}>
      <InputIcon
        placeholder="Buscar paciente"
        value={search}
        onChange={(event) => {
          setSearch(event.target.value);
        }}
        icon={searchIcon}
      />
      {renderCards()}
      <Button
        context={ComponentContext.PRIMARY}
        onClick={goToNewUser}
        margin="40px 0px 40px 0px"
      >
        Nuevo Paciente
      </Button>
    </div>
  );
};

export default Content;
