import AppContainer from "layouts/AppContainer";
import SectionContainer from "layouts/Section";
import { useParams } from "react-router";
import UserDetailComponent from "components/Users/Detail";
import { useGetUser, useGetUsers } from "services/User";
import Loading from "components/common/Loading";

const UserDetail = () => {
  const { id } = useParams<{ id: string }>();
  useGetUsers();
  const { data, loading, error } = useGetUser(id);

  return (
    <AppContainer>
      <SectionContainer
        title={data ? `${data.displayName} - ${data.email}` : ""}
      >
        {!loading && !error && data ? (
          <UserDetailComponent user={data} />
        ) : (
          <Loading />
        )}
      </SectionContainer>
    </AppContainer>
  );
};

export default UserDetail;
