import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeType) => ({
  container: {
    background: theme.palette.background.light,
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },

  textContainer: {
    flex: 1,
    display: "flex",
  },

  text: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    position: "relative",
    "& :first-child": {
      alignSelf: "flex-start",
    },
  },

  image: {
    height: 450,
    width: "auto",
    position: "relative",
    right: -150,
  },

  imageMobile: {
    display: "none",
  },

  "@media (max-width: 890px)": {
    image: {
      display: "none",
    },

    imageMobile: {
      display: "block",
      width: 280,
      alignSelf: "center",
      marginTop: 25,
    },

    text: {
      right: 0,
    },
  },
}));

export default useStyles;
