import React from "react";
import AppContainer from "layouts/AppContainer";
import Content from "components/AboutUs/Content";
import SectionContainer from "layouts/Section";

const AboutUs = () => {
  return (
    <AppContainer>
      <SectionContainer title="Quiénes somos">
        <Content />
      </SectionContainer>
    </AppContainer>
  );
};

export default AboutUs;
