import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeType) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    boxShadow: "0px 4px 12px 8px rgba(0, 0, 0, 0.05)",
    borderRadius: 7,
    backgroundColor: "rgba(255,255,255,0.7)",
    padding: "50px 81px 0px 81px",
    alignItems: "center",
  },

  "@media (max-width: 430px)": {
    container: { padding: 50 },
  },
}));

export default useStyles;
