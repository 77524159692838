import { createUseStyles } from "react-jss";


const useStyles = createUseStyles((theme: ThemeType) => ({
    container: {
        display: "flex",
        flexDirection: 'column',
        justifyContent: "center",
        alignItems: "center",
        position: 'relative',
        height: '100%'
    },

    overlay: {
        display: 'flex',
        flexDirection: 'column',
    },

    "@media (max-width: 750px)": {
        container: {
            paddingTop: 43,
            paddingBottom: 43,
        },

        saladIcon: {

        },
    },
}));

export default useStyles;
