import Container from "components/common/Container";
import Text from "components/common/Text";
import Todos from "components/common/Todos";
import { FC, useCallback } from "react";
import theme from "utils/theme";
import { MealName } from "utils/types";

interface MealProps {
  mealName: MealName;
  food: string[];
  onMealChange?: (meal: MealType) => void;
}

const Meal: FC<MealProps> = ({ mealName, food, onMealChange }) => {
  const onChange = useCallback(
    (todos: TodoType[]) => {
      if (onMealChange)
        onMealChange({ mealName, food: todos.map((todo) => todo.description) });
    },
    [onMealChange, mealName]
  );
  return (
    <Container style={{ flex: 1, alignSelf: "stretch" }}>
      <div>
        <Text type="h3">{mealName}</Text>
      </div>
      <Todos
        onBlur={() => {}}
        initialTodos={food}
        todos={food || []}
        onTodosChange={onChange}
        title="Items"
        placeholder={"Ingrese item..."}
      />
      {!food.length && (
        <div
          style={{
            height: 60,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            alignSelf: "stretch",
            borderRadius: 10,
            backgroundColor: theme.palette.primary.light,
          }}
        >
          <Text weight="500">Agregue los items para el/la {mealName}</Text>
        </div>
      )}
    </Container>
  );
};

export default Meal;
