import salad from "assets/salad.png";
import SectionContainer from "layouts/Section";
import Content from "components/Login/Content";
import AppContainer from "layouts/AppContainer";
import Loading from "components/common/Loading";
import { useContext, useEffect } from "react";
import { AuthContext } from "contexts/AuthContext";
import { useHistory } from "react-router";
import { LOGIN_ROUTE, RECIPES_ROUTE } from "utils/routes";

const Login = () => {
  const { login, isLoading, error, user } = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    if (user) {
      history.push(RECIPES_ROUTE);
    } else {
      history.push(LOGIN_ROUTE);
    }
  }, [user, history]);

  return (
    <AppContainer>
      <img
        style={{
          maxHeight: 250,
          width: "auto",
          alignSelf: "flex-start",
          position: "absolute",
          bottom: 0,
          left: 0,
        }}
        src={salad}
        alt="mobile"
      />
      <SectionContainer
        hideHeader
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {isLoading ? (
          <Loading />
        ) : (
          <Content
            login={({ email, password }) => {
              login(email, password);
            }}
            error={error}
          />
        )}
      </SectionContainer>
    </AppContainer>
  );
};

export default Login;
