export enum ComponentContext {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  DEFAULT = "default",
  SUCCESS = "success",
  ERROR = "error",
  WARNING = "warning",
  INFO = "info",
  DISABLED = "disabled",
}

export type DayType =
  | "Lunes"
  | "Martes"
  | "Miercoles"
  | "Jueves"
  | "Viernes"
  | "Sabado"
  | "Domingo";

export type MealName =
  | "Desayuno"
  | "Almuerzo"
  | "Merienda"
  | "Cena"
  | "Colacion";

declare global {
  interface StatusType {
    loading: boolean;
    error: string | null;
  }

  interface MealType { mealName: MealName; food: string[] }

  interface MenuType {
    day: DayType;
    meals: MealType[];
  }
  interface UserType {
    id: string;
    email: string;
    displayName: string;
    menus?: MenuType[];
    role: "admin" | "user";
  }

  interface TodoType {
    id?: number;
    localId: string;
    user_id: number;
    created_at: Date;
    completed_at: string | null;
    updated_at: Date;
    description: string;
    saved_completed_at?: Date | null;
    saved_description?: string;
    deleted: boolean;
  }
  interface LinkType {
    text: string;
    link: string;
    action?: (item?: any) => any;
    context?: ComponentContext;
  }

  interface ServiceType {
    icon?: string;
    title: string;
    text: string;
  }

  interface Paragraph {
    key: string;
    text: string;
  }

  interface ImageContent {
    key: string;
    src: string;
  }

  interface TextContent {
    title: string;
    content: (Paragraph | ImageContent)[];
  }
}
