import Text from "components/common/Text";
import { FunctionComponent } from "react";

import IntroComponent from "./Intro";
import imageIntro from "assets/intro.png";
import { useHistory } from "react-router";
import { CONTACT_ROUTE } from "utils/routes";

const Intro: FunctionComponent = () => {
  const history = useHistory();

  return (
    <IntroComponent
      title={
        <Text type="h1" weight="500">
          Encontrá lo que tu{" "}
          <Text type="h1" textDecoration="underline">
            cuerpo necesita
          </Text>
        </Text>
      }
      image={imageIntro}
      subtitle="Centro de nutrición y salud"
      button={{
        action: () => {
          history.push(CONTACT_ROUTE);
        },
        text: "Contactar",
      }}
    />
  );
};

export default Intro;
