import ServicesComponent from "./Services";

import service1 from "assets/service1.png";
import service2 from "assets/service2.png";
import service3 from "assets/service3.png";

const Services = () => {
  return (
    <ServicesComponent
      services={[
        {
          icon: service1,
          title: "Asesoramiento profesional",
          text:
            "Nuestro equipo de profesionales te asesorará en cuál es la mejor manera de obtener resultados de acuerdo a tus necesidades.",
        },
        {
          icon: service2,
          title: "Consejos y recetas",
          text:
            "Acompañando tu proceso, compartimos ideas, recetas y consejos para acompañarte mientras consigues tus objetivos de manera saludable.​",
        },
        {
          icon: service3,
          title: "Atención personalizada",
          text:
            "Nuestro equipo de profesionales, Nutricionistas y Licenciados en Educación Física están a tu disposición, atendiendo cada caso de manera personalizada.",
        },
      ]}
    />
  );
};

export default Services;
