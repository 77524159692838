import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeType) => ({
  container: {
    display: 'flex',
    backgroundColor: theme.palette.white.main,
    borderRadius: 6,
    alignItems: 'center',
    border: `1px solid #F0F0F0`,
    paddingLeft: 8,
  },

  input: {
    fontSize: 16,
    flex: 1,
    border: 0,
    color: theme.palette.black.main,
    padding: 8,
    background: 'transparent',
    paddingTop: 13,
    paddingBottom: 13,
    fontFamily: "Lato",
    alignSelf: 'stretch',
    '&:focus': {
      outline: 'none',
    }
  },
}));

export default useStyles;
