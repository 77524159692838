import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "..";

interface CounterState {
  value: number;
}

export const initialState: CounterState = {
  value: 0,
};

export const counterSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    increment: (state, action: PayloadAction<number>) => {},
    incrementSuccess: (state, action: PayloadAction<number>): void => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += action.payload;
    },
  },
});

export const { increment, incrementSuccess } = counterSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCount: (state: RootState) => number = (state: RootState) =>
  state.counter.value;

export const { reducer } = counterSlice;

export { counterSaga } from "./saga";
