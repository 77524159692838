import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeType) => ({
  container: {
    boxShadow: "0px 4px 12px 8px rgba(0, 0, 0, 0.05)",
    borderRadius: 7,
    margin: (props) => props.margin,
    cursor: (props) => props.cursor,
    "-webkit-tap-highlight-color": "transparent",
    overflow: "hidden",
    backgroundColor: theme.palette.white.main,
  },

  "@media (max-width: 500px)": {
    container: { margin: (props) => (props.margin ? 15 : 0) },
  },
}));

export default useStyles;
