import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeType) => ({
  container: {
    background: theme.palette.background.light,
    paddingTop: 120,
    paddingBottom: 120,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },

  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: 420,
  },

  triangle: {
    position: "absolute",
    bottom: 0,
    right: 0,
    height: "100%",
  },

  "@media (max-width: 750px)": {
    triangle: {
      display: "none",
    },
  },
}));

export default useStyles;
