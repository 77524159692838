import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import Home from "views/Home";
import AboutUs from "views/AboutUs";
import Recipes from "views/Recipes";
import Contact from "views/Contact";
import Detail from "views/Detail";
import NewRecipe from "views/Admin/NewRecipe";
import NewUser from "views/Admin/NewUser";

import Users from "views/Users";

import {
  ABOUT_US_ROUTE,
  CONTACT_ROUTE,
  HOME_ROUTE,
  LOGIN_ROUTE,
  RECIPES_ROUTE,
  ROOT_ROUTE,
  NEW_RECIPE_ROUTE,
  NEW_USER_ROUTE,
  USERS_ROUTE,
  MENU_ROUTE,
} from "utils/routes";
import Login from "views/Login";
import AuthProvider from "contexts/AuthContext";
import UserDetail from "views/Users/Detail";
import Menu from "views/Menu";

function App() {
  return (
    <Router>
      <AuthProvider>
        <Switch>
          <Route path={`${NEW_RECIPE_ROUTE}/:id`}>
            <NewRecipe />
          </Route>
          <Route path={NEW_RECIPE_ROUTE}>
            <NewRecipe />
          </Route>
          <Route path={CONTACT_ROUTE}>
            <Contact />
          </Route>
          <Route path={MENU_ROUTE}>
            <Menu />
          </Route>
          <Route path={LOGIN_ROUTE}>
            <Login />
          </Route>
          <Route path={`${RECIPES_ROUTE}/editar/:id`}>
            <NewRecipe />
          </Route>
          <Route path={`${RECIPES_ROUTE}/:id`}>
            <Detail />
          </Route>
          <Route path={NEW_USER_ROUTE}>
            <NewUser />
          </Route>
          <Route path={RECIPES_ROUTE}>
            <Recipes />
          </Route>
          <Route path={`${USERS_ROUTE}/:id`}>
            <UserDetail />
          </Route>
          <Route path={USERS_ROUTE}>
            <Users />
          </Route>
          <Route path={ABOUT_US_ROUTE}>
            <AboutUs />
          </Route>
          <Route path={HOME_ROUTE}>
            <Home />
          </Route>
          <Route path={ROOT_ROUTE}>
            <Redirect to={HOME_ROUTE} />
          </Route>
        </Switch>
      </AuthProvider>
    </Router>
  );
}

export default App;
