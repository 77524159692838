import AppContainer from "layouts/AppContainer";
import Content from "components/Admin/NewUser";
import SectionContainer from "layouts/Section";

const NewUser = () => {
  return (
    <AppContainer>
      <SectionContainer title="Nuevo Paciente">
        <Content />
      </SectionContainer>
    </AppContainer>
  );
};

export default NewUser;
