import Text from "components/common/Text";
import Input from "components/common/Input";
import Button from "components/common/Button";
import { ComponentContext } from "utils/types";
import { useForm } from "react-hook-form";
import { useCreateUser } from "services/User";
import Loading from "components/common/Loading";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";

const NewUser = () => {
  const history = useHistory();
  const { register, handleSubmit } = useForm();
  const [message, setMessage] = useState();
  const [createUser, { loading, data, error }] = useCreateUser(() => {
    history.goBack();
  });

  useEffect(() => {
    if (!!message) {
      setTimeout(() => {
        setMessage(null);
      }, [2000]);
    }
  }, [message]);

  useEffect(() => {
    if (!loading && !error && data) {
      setMessage("Paciente creado correctamente");
    } else if (!loading && error) {
      setMessage("Error al crear el paciente, intente nuevamente");
    }
  }, [loading, data, error]);

  if (loading) return <Loading />;

  return (
    <div>
      {!!message && <p>{message}</p>}
      <form
        onSubmit={handleSubmit(async (formData) => {
          try {
            await createUser({
              variables: {
                username: `${formData.name}${formData.lastName}`,
                email: formData.email,
                displayName: `${formData.name} ${formData.lastName}`,
                role: "user",
                password: formData.password,
              },
            });
          } catch (error) {
            console.log("error", error);
          }
        })}
      >
        <div style={{ flex: 1, paddingRight: 50, marginTop: 20 }}>
          <Text>Nombre</Text>
          <Input
            inputRef={register}
            style={{ width: "100%" }}
            type="text"
            placeholder="Nombre"
            name="name"
            onChange={() => {}}
          />
        </div>
        <div style={{ flex: 1, paddingRight: 50, marginTop: 20 }}>
          <Text>Apellido</Text>
          <Input
            inputRef={register}
            style={{ width: "100%" }}
            type="text"
            placeholder="Nombre"
            name="lastName"
            onChange={() => {}}
          />
        </div>
        <div style={{ flex: 1, paddingRight: 50, marginTop: 20 }}>
          <Text>Email</Text>
          <Input
            inputRef={register}
            style={{ width: "100%" }}
            type="text"
            placeholder="Email"
            name="email"
            onChange={() => {}}
          />
        </div>
        <div style={{ flex: 1, paddingRight: 50, marginTop: 20 }}>
          <Text>Contraseña</Text>
          <Input
            inputRef={register}
            style={{ width: "100%" }}
            type="text"
            placeholder="Contraseña"
            name="password"
            onChange={() => {}}
          />
        </div>
        <Button context={ComponentContext.PRIMARY} margin="40px 0px 40px 0px">
          Crear
        </Button>
      </form>
    </div>
  );
};

export default NewUser;
