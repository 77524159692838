import Loading from "components/common/Loading";
import AppContainer from "layouts/AppContainer";
import SectionContainer from "layouts/Section";
import { useSelector } from "react-redux";
import { getUsersSelector } from "reducers/Users";
import { useGetUsers } from "services/User";
import Content from "../../components/Users/Content";

const Users = () => {
  const users = useSelector(getUsersSelector);
  useGetUsers();

  return (
    <AppContainer>
      <SectionContainer title="Pacientes">
        {users.length ? <Content users={users} /> : <Loading />}
      </SectionContainer>
    </AppContainer>
  );
};

export default Users;
