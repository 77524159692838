import React, { FunctionComponent } from "react";
import useStyles from "./styles";

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  value?: string;
  inputRef?: React.LegacyRef<HTMLInputElement>;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Input: FunctionComponent<InputProps> = ({
  value,
  onChange,
  inputRef,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <input
      ref={inputRef}
      value={value}
      onChange={onChange}
      {...rest}
      className={`${classes.container} ${rest.className && rest.className}`}
    />
  );
};

export default Input;
