import AppContainer from "layouts/AppContainer";
import Content from "components/Recipes/Content";
import SectionContainer from "layouts/Section";
import User from "components/Recipes/User";
import Guest from "components/Recipes/Guest";
import { useContext, useEffect } from "react";
import { AuthContext } from "contexts/AuthContext";
import Loading from "components/common/Loading";
import Error from "components/common/Error";
import { useGetRecipes } from "services/Recipes";

const Recipes = () => {
  const { user } = useContext(AuthContext);
  const { loading, error, refetch } = useGetRecipes();

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <AppContainer>
      <SectionContainer title="Recetario" subtitle="TUS DÍAS EQUILIBRADOS">
        <Content>
          {loading ? (
            <Loading />
          ) : !error && user ? (
            <User isAdmin={user.role === "admin"} />
          ) : error && user ? (
            <Error error={error} />
          ) : (
            <Guest />
          )}
        </Content>
      </SectionContainer>
    </AppContainer>
  );
};

export default Recipes;
