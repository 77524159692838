import { useMutation, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "reducers";
import {
  deleteUserSuccess,
  getUserSelector,
  getUsersSuccess,
  getUserSuccess,
} from "reducers/Users";

export const LOGIN = gql`
  mutation login($email: String, $password: String) {
    login(email: $email, password: $password)
  }
`;

export const GET_PROFILE = gql`
  query {
    profile {
      _id
      email
      username
      role
      menus {
        day
        meals {
          mealName
          food
        }
      }
    }
  }
`;

export const GET_USERS = gql`
  query {
    users {
      _id
      username
      email
      role
      displayName
      menus {
        day
        meals {
          mealName
          food
        }
      }
    }
  }
`;

export const GET_USER = gql`
  query getUser($id: ID) {
    user(id: $id) {
      _id
      username
      email
      role
      displayName
      menus {
        day
        meals {
          mealName
          food
        }
      }
    }
  }
`;

const parseUser = (user: any) => ({
  ...user,
  id: user._id,
});

export const useGetUsers = () => {
  const response = useQuery(GET_USERS, {
    fetchPolicy: "cache-and-network",
  });
  const dispatch = useDispatch();

  const { error, loading, data } = response;

  useEffect(() => {
    if (!error && !loading && data) {
      dispatch(
        getUsersSuccess(
          data.users.map((user: any) => ({
            ...user,
            id: user._id,
          })) as UserType[]
        )
      );
    }
  }, [error, loading, data, dispatch]);

  return data
    ? { ...response, data: data.users.map((user: any) => parseUser(user)) }
    : response;
};

export const useGetUser = (id: string) => {
  const response = useQuery(GET_USER, {
    variables: { id },
    fetchPolicy: "cache-and-network",
  });

  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => getUserSelector(state, id));

  const { error, loading, data } = response;

  useEffect(() => {
    if (!error && !loading && data && data.user) {
      dispatch(getUserSuccess({ ...data.user, id: data.user._id }));
    }
  }, [error, loading, data, dispatch]);

  return { ...response, data: user, loading: loading || !user };
};

const CREATE_USER = gql`
  mutation register(
    $username: String
    $email: String
    $displayName: String
    $password: String
    $role: String
  ) {
    register(
      username: $username
      email: $email
      password: $password
      displayName: $displayName
      role: $role
    )
  }
`;

const UPDATE_USER = gql`
  mutation updateUser(
    $id: String!
    $username: String
    $email: String
    $displayName: String
    $role: String
    $menus: [MenusTypeInput]
  ) {
    updateUser(
      id: $id
      username: $username
      email: $email
      displayName: $displayName
      role: $role
      menus: $menus
    ) {
      username
      email
      role
      displayName
      menus {
        day
        meals {
          mealName
          food
        }
      }
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id)
  }
`;

export const useCreateUser = (onCompleted: () => void) => {
  const createUserMutation = useMutation(CREATE_USER, {
    onCompleted,
  });
  return createUserMutation;
};

export const useUpdateUser = (onCompleted: () => void) => {
  const updateUserMutation = useMutation(UPDATE_USER, {
    onCompleted,
  });
  return updateUserMutation;
};

export const useDeleteUser = (onCompleted: () => void) => {
  const dispatch = useDispatch();

  const deleteUserMutation = useMutation(DELETE_USER, {
    onCompleted: (data: { deleteUser: string }) => {
      dispatch(deleteUserSuccess(data.deleteUser));
      onCompleted();
    },
  });

  return deleteUserMutation;
};
