import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeType) => ({
  container: {
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 100,
    backgroundColor: theme.palette.white.main,
    display: "flex",
    height: 83,
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },

  logo: {
    marginLeft: 20,
  },

  menuToggle: {
    outline: "none",
    border: "none",
    "-webkit-user-select": "none",
    "-moz-user-select": "none",
    "-ms-user-select": "none",
    '-webkit-tap-highlight-color': 'transparent',
    cursor: "pointer",
    position: "absolute",
    top: 18,
    right: 15,
    width: 50,
    height: 50,
    borderRadius: "50%",
    background: "transparent",
  },

  nav: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    width: 300,
    height: "100vh",
    background: theme.palette.primary.light,
  },

  itemsContainer: {
  },

  navContainer: {
    display: "none",
  },

  itemsContainerMobile: {
    display: 'none',
  },

  itemsContainerMobileOpen: {},

  "@media (max-width: 850px)": {
    button: {
      width: 200,
    },
    navContainer: {
      display: "inherit",
    },
    
    itemsContainerMobileOpen: {
      position: "absolute",
      marginRight: 0,
      top: 80,
      display: "flex",
      flexDirection: "column",
      right: 0,
      width: 230,
    },

    itemsContainer: {
      display: 'none'
    }
  },
}));

export default useStyles;
