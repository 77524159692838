import { FC } from "react";

interface ContainerProps {
  style?: React.CSSProperties | undefined;
}

const Container: FC<ContainerProps> = ({ children, style }) => {
  return (
    <div
      style={{
        flex: 1,
        padding: 10,
        display: "flex",
        flexDirection: "column",
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export default Container;
