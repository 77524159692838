import React from "react";
import { motion } from "framer-motion";

import Todo from "./Todo";

interface TodosProps {
  todos: TodoType[];
  onUpdate: (localId: string, description: string) => void;
  onClickTodo: (todo: TodoType) => void;
  onDeleteTodo: (todo: TodoType) => void;
}

function Todos({ todos, onClickTodo, onDeleteTodo, onUpdate }: TodosProps) {
  return (
    <motion.div>
      {todos.map((todo) => (
        <Todo
          key={todo.localId}
          todo={todo}
          onUpdate={onUpdate}
          onClick={onClickTodo}
          onDelete={onDeleteTodo}
        />
      ))}
    </motion.div>
  );
}

export default Todos;
