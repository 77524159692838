import Text from "components/common/Text";
import React from "react";
import useStyles from "./styles";

interface SectionHeaderProps {
  title: string;
  subtitle?: string;
}

const SectionHeader = ({ title, subtitle }: SectionHeaderProps) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {subtitle && <Text type="h5">{subtitle}</Text>}
      <Text type="h2">{title}</Text>
    </div>
  );
};

export default SectionHeader;
