export const ROOT_ROUTE = "/";
export const HOME_ROUTE = "/inicio";
export const RECIPES_ROUTE = "/recetario";
export const ABOUT_US_ROUTE = "/quienes-somos";
export const CONTACT_ROUTE = "/contacto";
export const LOGIN_ROUTE = "/login";
export const DETAIL_ROUTE = "/detail";
export const NEW_RECIPE_ROUTE = "/recetario/agregar";
export const USER_ROUTE = "/user";
export const USERS_ROUTE = "/users";
export const NEW_USER_ROUTE = "/new_user";
export const MENU_ROUTE = "/menu";
