import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeType) => ({
  container: {
    background: theme.palette.primary.light,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap",
  },

  row: {
    display: "flex",
  },

  icon: {
    marginBottom: 30,
    width: 69,
    height: "fit-content",
  },

  cardContainer: {
    display: "flex",
    flex: 1,
    flexShrink: 0,
    flexDirection: "column",
    alignItems: "center",
  },

  separator: {},

  "@media (max-width: 1200px)": {
    container: {
      flexDirection: "column",
    },
  },

  "@media (max-width: 1080px)": {
    separator: {
      display: "none",
    },
  },
}));

export default useStyles;
