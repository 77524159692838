import { motion } from "framer-motion";
import { FunctionComponent } from "react";
import useStyles from "./styles";
import Text from "../../../common/Text";
import clock from "assets/clock.png";
import { useHistory } from "react-router";
import { RECIPES_ROUTE } from "utils/routes";
import theme from "utils/theme";
import { ReactComponent as ReactLogo } from "assets/editIcon.svg";

const Row: FunctionComponent<{
  margin?: number | string;
  name: string;
  id: string;
  time: number;
  cursor?: string;
  onEdit?: () => void;
  animated?: boolean;
}> = ({
  children,
  margin,
  name,
  time,
  cursor,
  animated = false,
  id,
  onEdit,
}) => {
  const classes = useStyles({ margin, cursor });
  const history = useHistory();
  return (
    <motion.div
      animate={{
        opacity: 1,
        transition: {
          stiffness: 150,
          duration: 0.5,
        },
      }}
      initial={{
        opacity: 0,
      }}
      whileHover={
        animated ? { scale: 1.05, y: -10, transition: { stiffness: 200 } } : {}
      }
      className={classes.container}
      onClick={() => {
        history.push(`${RECIPES_ROUTE}/${id}`);
      }}
    >
      <Text>{name}</Text>
      <div style={{ flexDirection: "row", display: "flex" }}>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            width: 135,
            marginRight: 8,
          }}
        >
          <img src={clock} style={{ height: 20, marginRight: 20 }} alt="icon" />
          <Text>{time} minutos</Text>
        </div>
        {onEdit && (
          <button
            className={classes.editButton}
            onClick={(e) => {
              e.stopPropagation();
              history.push(`${RECIPES_ROUTE}/editar/${id}`);
            }}
          >
            <ReactLogo
              style={{
                width: 20,
                height: 20,
                fill: theme.palette.primary.dark,
              }}
            />
          </button>
        )}
      </div>
    </motion.div>
  );
};

export default Row;
