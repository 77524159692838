import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeType) => ({
  p: {
    fontSize: 16,
  },

  h1: {
    fontSize: 42,
  },

  h2: {
    fontSize: 28,
  },

  h3: {
    fontSize: 24,
  },

  h3R: {
    fontSize: 18,
  },

  h4: {
    fontSize: 18,
  },

  h5: {
    fontSize: 14,
  },

  h6: {
    fontSize: 14,
  },

  primary: {
    color: theme.palette.primary.dark,
  },

  secondary: {
    color: theme.palette.secondary.main,
  },

  default: {
    color: theme.palette.black.main,
  },

  success: {},

  error: {
    color: theme.palette.red.main,
  },

  warning: {},

  info: {
    color: '#9A9A9A',
    textDecoration: 'underline',
    fontsize: 14,
  },

  disabled: {},

  text: {
    fontFamily: "Poppins",
    margin: (props) => props.margin || 0,
    textAlign: (props) => props.align,
    fontWeight: (props) => (props.weight ? props.weight : "inherit"),
    textDecoration: (props) => props.textDecoration,
    textDecorationColor: (props) =>
      props.textDecoration === "underline" && theme.palette.secondary.dark,
    textDecorationThickness: (props) =>
      props.textDecoration === "underline" && 5,
    textDecorationOffset: 5,
  },

  "@media (max-width: 990px)": {
    h3R: {
      fontSize: 14
    }
  },

  "@media (max-width: 750px)": {
    p: {
      fontSize: 16,
    },

    h1: {
      fontSize: 24,
    },

    h2: {
      fontSize: 18,
    },

    h3: {
      fontSize: 18,
    },

    h4: {
      fontSize: 16,
    },

    h5: {
      fontSize: 14,
    },
  },

  "@media (max-width: 450px)": {
    h6: {
      fontSize: 10,
    }
  }
}));

export default useStyles;
