import { FC } from "react";

import Meal from "./Meal";
import Container from "components/common/Container";
import { ComponentContext } from "utils/types";
import Button from "components/common/Button";
import Text from "components/common/Text";

interface MenuProps {
  status?: StatusType;
  menu: MenuType;
  progress?: "Vacia" | "En progreso";
  onAddMenu: (menu: MenuType) => void;
  onMenuChange: (menu: MenuType) => void;
}

const Menu: FC<MenuProps> = ({
  menu,
  menu: { meals },
  onMenuChange,
  onAddMenu,
  status,
}) => {
  return (
    <Container
      style={{
        backgroundColor: "white",
        margin: 20,
        borderRadius: 9,
        alignItems: "center",
        width: 600,
      }}
    >
      <Text type="h1">{menu.day}</Text>
      <Container
        style={{
          alignSelf: "stretch",
          position: "relative",
          overflow: "scroll",
        }}
      >
        {meals.map((meal) => (
          <Meal
            key={meal.mealName}
            meal={meal}
            onMealChange={(meal) => {
              onMenuChange({
                ...menu,
                meals: meals.map((previousMeal) =>
                  previousMeal.mealName === meal.mealName ? meal : previousMeal
                ),
              });
            }}
          />
        ))}
      </Container>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: 20,
          bottom: 0,
          right: 0,
        }}
      >
        <Button
          loading={status?.loading}
          alignSelf="flex-end"
          text="Agregar Menú"
          context={ComponentContext.PRIMARY}
          onClick={() => {
            onAddMenu(menu);
          }}
        />
      </div>
    </Container>
  );
};

export default Menu;
