import AppContainer from "layouts/AppContainer";
import Content from "components/Contact/Content";
import SectionContainer from "layouts/Section";

const Contact = () => {
  return (
    <AppContainer>
      <SectionContainer title="Contacto">
        <Content />
      </SectionContainer>
    </AppContainer>
  );
};

export default Contact;
