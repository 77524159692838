import { PDFDownloadLink } from "@react-pdf/renderer";
import Text from "components/common/Text";
import MyDocument from "components/MenuPDF";
import { FC } from "react";

interface MenuProps {
  menu: MenuType[];
}

interface MealProps {
  meal: MenuType;
}

const Meal: FC<MealProps> = ({ meal: { day, meals } }) => {
  return (
    <div style={{ backgroundColor: "white", borderRadius: 12 }}>
      <div
        style={{
          padding: 20,
          marginBottom: 10,
        }}
      >
        <Text type="h3">{day}</Text>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
            borderRadius: 12,
          }}
        >
          {meals.map((meal) => (
            <div
              key={meal.mealName}
              style={{
                maxWidth: 130,
                minWidth: 90,
                margin: 5,
                marginTop: 15,
              }}
            >
              <Text type="h4" weight="700">
                {meal.mealName}
              </Text>
              <ul style={{ paddingLeft: 20 }}>
                {meal.food.map((food) => (
                  <li style={{ padding: 0, margin: 0 }}>
                    <Text type="p" key={food}>
                      {food}
                    </Text>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const Menu: FC<MenuProps> = ({ menu }) => {
  return (
    <div>
      <div style={{ marginBottom: 18 }}>
        <PDFDownloadLink
          document={<MyDocument menus={menu} />}
          fileName="menu semanal"
        >
          Descargar menu
        </PDFDownloadLink>
      </div>

      {menu.map((meal) => (
        <Meal key={meal.day} meal={meal} />
      ))}
    </div>
  );
};

export default Menu;
