import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeType) => ({
  container: {
    background: theme.palette.primary.light,
    display: "flex",
    flexDirection: 'column',
    justifyContent: "center",
    paddingLeft: 72,
    paddingRight: 72,
  },

  "@media (max-width: 750px)": {
    container: {
      paddingTop: 43,
      paddingBottom: 43,
      paddingLeft: 45,
      paddingRight: 45,
    },
  },

  "@media (max-width: 450px)": {
    container: {
      paddingTop: 43,
      paddingBottom: 43,
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
}));

export default useStyles;
