import { useMutation, useQuery } from "@apollo/client";
import firebase from "firebase/app";
import "firebase/firestore";
import gql from "graphql-tag";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "reducers";
import {
  createRecipeSuccess,
  getRecipeSelector,
  getRecipesSuccess,
  updateRecipeSuccess,
} from "reducers/Recipes";

export const GET_RECIPES = gql`
  query {
    recipes {
      _id
      name
      category
      calories
      cookTime
      ingredients
      portions
      steps
    }
  }
`;

export const useGetRecipes = () => {
  const response = useQuery(GET_RECIPES);
  const dispatch = useDispatch();

  const { error, loading, data } = response;

  useEffect(() => {
    if (!error && !loading && data) {
      dispatch(
        getRecipesSuccess(
          data.recipes.map((recipe: any) => ({
            ...recipe,
            id: recipe._id,
          })) as RecipeType[]
        )
      );
    }
  }, [error, loading, data, dispatch]);

  return response;
};

export const GET_RECIPE = gql`
  query getRecipe($id: ID) {
    recipe(id: $id) {
      _id
      name
      category
      calories
      cookTime
      ingredients
      portions
      steps
    }
  }
`;

export const useGetRecipe = (id: string) => {
  const response = useQuery(GET_RECIPE, {
    variables: { id },
    fetchPolicy: "cache-and-network",
  });

  const dispatch = useDispatch();
  const recipe = useSelector((state: RootState) =>
    getRecipeSelector(state, id)
  );

  const { error, loading, data } = response;

  useEffect(() => {
    if (!error && !loading && data && data.recipe) {
      dispatch(getRecipesSuccess([{ ...data.recipe, id: data.recipe._id }]));
    }
  }, [error, loading, data, dispatch]);

  return { ...response, data: recipe, loading: loading || !recipe };
};

export const CREATE_RECIPE = gql`
  mutation createRecipe(
    $name: String
    $category: String
    $calories: Int
    $cookTime: Int
    $ingredients: [String]
    $portions: Int
    $steps: [String]
  ) {
    addRecipe(
      name: $name
      category: $category
      calories: $calories
      cookTime: $cookTime
      ingredients: $ingredients
      portions: $portions
      steps: $steps
    ) {
      name
      category
      calories
      cookTime
      ingredients
      steps
      portions
    }
  }
`;

export const UPDATE_RECIPE = gql`
  mutation updateRecipe(
    $id: String!
    $name: String
    $category: String
    $calories: Int
    $cookTime: Int
    $ingredients: [String]
    $portions: Int
    $steps: [String]
  ) {
    updateRecipe(
      id: $id
      name: $name
      category: $category
      calories: $calories
      cookTime: $cookTime
      ingredients: $ingredients
      portions: $portions
      steps: $steps
    ) {
      _id
      name
      category
      calories
      cookTime
      ingredients
      steps
      portions
    }
  }
`;

export const useCreateRecipe = () => {
  const addRecipeMutation = useMutation(CREATE_RECIPE);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      addRecipeMutation[1].data &&
      !addRecipeMutation[1].error &&
      !addRecipeMutation[1].loading
    ) {
      dispatch(createRecipeSuccess(addRecipeMutation[1].data.addRecipe));
    }
  }, [addRecipeMutation, dispatch]);

  return addRecipeMutation;
};

export const useUpdateRecipe = () => {
  const updateRecipe = useMutation(UPDATE_RECIPE);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      updateRecipe[1].data &&
      !updateRecipe[1].error &&
      !updateRecipe[1].loading
    ) {
      dispatch(
        updateRecipeSuccess({
          ...updateRecipe[1].data,
          id: updateRecipe[1].data._id,
        })
      );
    }
  }, [updateRecipe, dispatch]);

  return updateRecipe;
};

export const getRecipesService = async () => {
  const querySnapshot = await firebase.firestore().collection("recipes").get();

  let recipes: any = [];

  querySnapshot.forEach((doc) => {
    const recipe = { ...doc.data(), id: doc.id };

    recipes = [...recipes, recipe];
  });

  return recipes;
};

export const getRecipeService = async (recipeId: string) => {
  const querySnapshot = await firebase
    .firestore()
    .collection("recipes")
    .doc(recipeId)
    .get();

  let recipe: any = querySnapshot.data();

  return recipe;
};

export const createRecipeService = async (
  recipe: Pick<
    RecipeType,
    "name" | "category" | "cookTime" | "ingredients" | "portions" | "steps"
  >
) => {
  const recipeAdded = (
    await (await firebase.firestore().collection("recipes").add(recipe)).get()
  ).data();

  return recipeAdded;
};
