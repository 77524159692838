import React from "react";

import useStyles from "./styles";
import Services from "./Services";
import Description from "./Description";

const Content = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Description />
      <Services />
    </div>
  );
};

export default Content;
