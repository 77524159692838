
import Text from "components/common/Text";

interface TextIconProps{
    text:string;
    icon: string;
}

const TextIcon = ({text , icon}: TextIconProps) => {
    return (
        <div style={{display: "flex", flexDirection:"row", alignItems: "center"}}>
            <img src={icon} alt="" style={{width: 20, height:20, marginTop: 20, marginRight: 10}}></img>
            <Text type="p" margin="20px 0px 0px 0px"> {text} </Text>
        </div>
    )
}

export default TextIcon
