import { PayloadAction } from "@reduxjs/toolkit";
import { takeEvery, put, call } from "redux-saga/effects";

import { increment, incrementSuccess } from ".";
import { incrementCounterService } from "services/Counter";

export function* incrementCounterSaga(action: PayloadAction<number>) {
  try {
    yield call(incrementCounterService, action.payload);
    yield put(incrementSuccess(action.payload));
  } catch (e) {}
}

export function* counterSaga() {
  yield takeEvery(increment.type, incrementCounterSaga);
}
