import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeType) => ({
  container: {
    backgroundColor: theme.palette.white.main,
    flex: 1,
    display: "flex",
    padding: 50,
    borderRadius: 7,
    marginBottom: 20,
    alignItems: "center",
    marginRight: 20,
  },

  "@media (max-width: 1080px)": {
    container: {
      padding: 10,
      margin: 10,
    },
  },
}));

export default useStyles;
