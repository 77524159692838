import React, { FunctionComponent } from "react";
import useStyles from "./styles";

interface InputIconProps extends React.ButtonHTMLAttributes<HTMLInputElement> {
  value: string;
  icon: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputIcon: FunctionComponent<InputIconProps> = ({
  value,
  icon,
  onChange,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <img src={icon} alt="icon" style={{ width: 20, height: 20 }} />
      <input
        value={value}
        onChange={onChange}
        {...rest}
        className={`${classes.input} ${rest.className && rest.className}`}
      />
    </div>
  );
};

export default InputIcon;
