import AppContainer from "layouts/AppContainer";
import Content from "components/Detail";
import SectionContainer from "layouts/Section";
import { useParams } from "react-router";
import { useGetRecipe } from "services/Recipes";

const Detail = () => {
  const { id } = useParams<{ id: string }>();
  const { data } = useGetRecipe(id);

  return (
    <AppContainer>
      <SectionContainer title={data?.name}>
        {data && <Content recipe={data} />}
      </SectionContainer>
    </AppContainer>
  );
};

export default Detail;
