import { useParams, useRouteMatch } from "react-router";

import AppContainer from "layouts/AppContainer";
import Content from "components/Admin/NewRecipe";
import SectionContainer from "layouts/Section";
import { useGetRecipe } from "services/Recipes";
import Loading from "components/common/Loading";
import { NEW_RECIPE_ROUTE } from "utils/routes";

const NewRecipe = () => {
  const { id } = useParams<{ id: string }>();
  const match = useRouteMatch({
    path: NEW_RECIPE_ROUTE,
    strict: true,
    sensitive: true,
  });
  const { data, loading } = useGetRecipe(id);
  return (
    <AppContainer>
      <SectionContainer title="Nueva Receta">
        {!!id && !data && loading ? (
          <Loading />
        ) : (
          <Content recipe={match ? undefined : data} />
        )}
      </SectionContainer>
    </AppContainer>
  );
};

export default NewRecipe;
