import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeType) => ({
  container: {
    display: "flex",
    width: "100%",
    paddingTop: 74,
    paddingBottom: 74,
    justifyContent: "center",
    flexWrap: "wrap",
  },

  serviceContainer: {
    maxWidth: 300,
    padding: 33,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },

  serviceBottom: {
    height: 70,
    width: "100%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },

  serviceIcon: {
    display: "flex",
    justifyContent: "center ",
    alignItems: "center",
    height: 77,
    width: 77,
    borderRadius: 50,
    backgroundColor: theme.palette.secondary.light,
    marginBottom: 25,
  },

  "@media (max-width: 830px)": {
    image: { display: "none" },

    container: {
      alignItems: "center",
    },
  },
}));

export default useStyles;
