import { useSelector } from "react-redux";

import User from "./User";
import { RootState } from "reducers";
import { getRecipesSelector } from "reducers/Recipes";
import { useState } from "react";

const UserContainer = ({ isAdmin }: { isAdmin: boolean }) => {
  const [search, setSearch] = useState<{ text?: string; type?: string }>({});
  const recipes = useSelector((state: RootState) =>
    getRecipesSelector(state, search)
  );

  return (
    <User
      isAdmin={isAdmin}
      recipes={recipes}
      onSearchChanged={(search) => {
        setSearch((prev) => ({
          ...prev,
          ...search,
        }));
      }}
      search={search}
    />
  );
};

export default UserContainer;
