import Intro from "components/Home/Intro";
import Services from "components/Home/Services";
import AppContainer from "layouts/AppContainer";
import ExploreRecipes from "components/Home/ExploreRecipes";

const Home = () => {
  return (
    <AppContainer>
      <Intro />
      <Services />
      <ExploreRecipes />
    </AppContainer>
  );
};

export default Home;