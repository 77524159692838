import InputIcon from "components/common/InputIcon";
import Card from "./Card";
import Text from "components/common/Text";
import Row from "./Row";

import carne from "assets/carne.png";
import vegetales from "assets/vegetales.png";
import tartas from "assets/tartas.png";
import postres from "assets/postres.png";
import meriendas from "assets/meriendas.png";
import searchIcon from "assets/searchIcon.png";

const comidas = [
  { foto: carne, name: "Carne" },
  { foto: vegetales, name: "Vegetales" },
  { foto: tartas, name: "Tartas" },
  { foto: postres, name: "Postres" },
  { foto: meriendas, name: "Meriendas" },
];

const Cards = ({
  type,
  onTypeChanged,
}: {
  type: string;
  onTypeChanged: (type: string) => void;
}) => {
  return (
    <>
      {comidas.map((comida, index) => (
        <Card
          isSelected={type === comida.name}
          onClick={() => {
            if (comida.name === type) {
              onTypeChanged("");
            } else onTypeChanged(comida.name);
          }}
          key={comida.name}
          margin={`20px ${
            index + 1 === comidas.length ? "0px" : "20px"
          } 20px 0px`}
          cursor="pointer"
          img={comida.foto}
          title="titulo"
        >
          <Text type="h6" align="left">
            {comida.name}
          </Text>
        </Card>
      ))}
    </>
  );
};

interface UserProps {
  recipes: RecipeType[];
  isAdmin: boolean;
  search: { text?: string; type?: string };
  onSearchChanged: (search: { text?: string; type?: string }) => void;
}

const User = ({ recipes, onSearchChanged, search, isAdmin }: UserProps) => {
  const renderRecipes = () => {
    return recipes.map((recipe) => (
      <Row
        id={recipe.id}
        key={recipe.id}
        margin={"0px 0px 8px 0px"}
        cursor="pointer"
        name={recipe.name}
        time={recipe.cookTime}
        onEdit={isAdmin ? () => {} : undefined}
      ></Row>
    ));
  };

  if (!recipes.length && !search.text && !search.type) {
    return <Text type="h1">No se han agregado recetas por el momento</Text>;
  }

  return (
    <>
      <InputIcon
        placeholder="Buscar receta"
        value={search.text || ""}
        onChange={(event) => {
          onSearchChanged({ text: event.target.value });
        }}
        icon={searchIcon}
      />
      <div style={{ marginTop: 20 }}>
        <div
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
          <Cards
            type={search.type || ""}
            onTypeChanged={(type) => {
              onSearchChanged({ type });
            }}
          />
        </div>
        <Text type="h2" align="left" margin={"20px 0px 20px 0px"}>
          Recetas destacadas
        </Text>
        <div>{renderRecipes()}</div>
      </div>
    </>
  );
};

export default User;
