import AppContainer from "layouts/AppContainer";
import SectionContainer from "layouts/Section";
import MenuComponent from "components/Menu";
import { useContext } from "react";
import { AuthContext } from "contexts/AuthContext";

const Menu = () => {
  const { user } = useContext(AuthContext);

  return (
    <AppContainer>
      <SectionContainer title="Menú Semanal">
        {user?.menus && <MenuComponent menu={user.menus} />}
      </SectionContainer>
    </AppContainer>
  );
};

export default Menu;
