import { ApolloError } from "@apollo/client";
import Text from "../Text";

interface ErrorProps {
  error: ApolloError;
}

const Error = ({ error }: ErrorProps) => {
  return <Text type="h1">{error}</Text>;
};

export default Error;
