import { FunctionComponent } from "react";

import useStyles from "./styles";
import Text from "components/common/Text";

import triangle from "assets/triangle.png";
import Button from "components/common/Button";
import { ComponentContext } from "utils/types";

interface ExploreRecipesProps {
  button: {
    text: string;
    action: () => void;
  };
  text: string;
}

const ExploreRecipes: FunctionComponent<ExploreRecipesProps> = ({
  text,
  button,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <Text type="h2" weight="500" align="center" margin={40}>
          {text}
        </Text>
      </div>
      <Button text={button.text} onClick={button.action} context={ComponentContext.PRIMARY} />
      <img className={classes.triangle} src={triangle} alt="triangle" />
    </div>
  );
};

export default ExploreRecipes;
