import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from "react-jss";

import "./firebaseConfig";
// import 'bootstrap/dist/css/bootstrap.min.css';

import "./index.css";
import App from "./App";
import store from "store";
import theme from "utils/theme";

import {
  ApolloProvider,
  ApolloClient,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const httpLink = createHttpLink({
  uri: "https://mercedeslama-api.herokuapp.com/graphql",
  // uri: "localhost:3005/graphql",
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token
        ? `${token}`
        : "aeyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7InJvbGUiOiJhZG1pbiIsIl9pZCI6IjYwNjY1NzY0ZmRhNzQ0MWExNDI1OWI3MiIsInVzZXJuYW1lIjoibmF6YWJhbG0iLCJlbWFpbCI6Im1hcnRpbm5hemFiYWwyMEBnbWFpbC5jb20iLCJwYXNzd29yZCI6IjEyMzQiLCJkaXNwbGF5TmFtZSI6Ik1hcnRpbiIsImNyZWF0ZWRBdCI6IjIwMjEtMDQtMDFUMjM6Mjk6NDAuNzk0WiIsInVwZGF0ZWRBdCI6IjIwMjEtMDQtMDFUMjM6Mjk6NDAuNzk0WiIsIl9fdiI6MH0sImlhdCI6MTYxNzQ2MjEzOSwiZXhwIjoxNjIwMDU0MTM5fQ.DLLyXrBIKYbrVv7iY8Vh-NZCu3cuunzUBTnF6Q3wNyI",
    },
  };
});

const client = new ApolloClient({
  connectToDevTools: true,
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <React.StrictMode>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Provider>
    </React.StrictMode>
  </ApolloProvider>,
  document.getElementById("root")
);
