import firebase from "firebase/app";

import "firebase/auth";
import "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyC2Dci-3tgFDdg31-d_bYNYkkqSzapi4Gs",
  authDomain: "mercedeslama-324b3.firebaseapp.com",
  projectId: "mercedeslama-324b3",
  storageBucket: "mercedeslama-324b3.appspot.com",
  messagingSenderId: "393262850199",
  appId: "1:393262850199:web:5e8cc639c17484ff4f89d2",
  measurementId: "G-4S0GGMVJLB",
};

const app = firebase.initializeApp(firebaseConfig);

export default app;