import Button from "components/common/Button";
import Card from "components/common/Card";
import Text from "components/common/Text";
import { useHistory } from "react-router";
import { CONTACT_ROUTE, LOGIN_ROUTE } from "utils/routes";
import { ComponentContext } from "utils/types";

import useStyles from "./styles";

const Guest = () => {
  const classes = useStyles();
  const history = useHistory();

  const goToLogin = () => {
    history.push(LOGIN_ROUTE);
  };

  const goToContact = () => {
    history.push(CONTACT_ROUTE);
  };

  const Mobile = () => (
    <div className={classes.mobile}>
      <Card margin="35px 20px 0px 20px">
        <div className={classes.cardContainer}>
          <Text
            type="h3"
            context={ComponentContext.INFO}
            textDecoration="none"
            align="center"
            margin="20px 0px 16px 0px"
          >
            El acceso completo al recetario se encuentra reservado para nuestros
            pacientes.
          </Text>
          <Button
            margin="0px 0px 25px 0px"
            text="Ver recetario"
            size="medium"
            onClick={goToLogin}
            context={ComponentContext.PRIMARY}
          ></Button>
        </div>
      </Card>
      <Text
        type="h3"
        context={ComponentContext.INFO}
        textDecoration="none"
        align="center"
        margin="52px 0px 19px 0px"
      >
        Por consultas personalizadas, ponemos a tu disposición nuestros
        contactos.​
      </Text>
      <Button
        margin={0}
        text="Realizar consulta"
        onClick={goToContact}
        size="medium"
        context={ComponentContext.DEFAULT}
      ></Button>
    </div>
  );

  const Web = () => (
    <div className={classes.web}>
      <Text
        type="h3"
        context={ComponentContext.INFO}
        textDecoration="none"
        margin="20px 0px 8px 0px"
      >
        El acceso completo al recetario se encuentra reservado para nuestros
        pacientes.
      </Text>
      <Text
        type="h3"
        context={ComponentContext.INFO}
        textDecoration="none"
        margin="0px 0px 19px 0px"
      >
        Por consultas personalizadas, ponemos a tu disposición nuestros
        contactos.​
      </Text>
      <div className={classes.buttonsContainer}>
        <Button
          margin={0}
          text="Realizar consulta"
          onClick={goToContact}
          size="medium"
          context={ComponentContext.DEFAULT}
        ></Button>
        <Button
          margin={0}
          text="Ver recetario"
          size="medium"
          onClick={goToLogin}
          context={ComponentContext.PRIMARY}
        ></Button>
      </div>
    </div>
  );

  return (
    <>
      <Text type="h2">
        ¡ Más de 700 recetas para que tus días sean deliciosos, saludables y
        variados !
      </Text>
      <Web />
      <Mobile />
    </>
  );
};

export default Guest;
