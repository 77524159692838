import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeType) => ({
  container: {
    background: theme.palette.background.main,
    paddingTop: 40,
    paddingBottom: 40,
    display: "flex",
    flexDirection: "column",
    paddingLeft: 72,
    borderBottomRightRadius: 200,
    marginBottom: 58,
  },

  "@media (max-width: 750px)": {
    container: {
      paddingTop: 32,
      paddingBottom: 0,
      paddingLeft: 45,
      backgroundColor: theme.palette.background.light,
      borderBottomRightRadius: 0,
      marginBottom: 0,
    },
  },

  "@media (max-width: 450px)": {
    container: {
      paddingTop: 43,
      paddingBottom: 0,
      paddingLeft: 8,
      backgroundColor: theme.palette.background.light,
      borderBottomRightRadius: 0,
      marginBottom: 0,
    },
  },
}));

export default useStyles;
