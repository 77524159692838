import useStyles from "./styles";
import Text from "components/common/Text";

import imageMobile from "assets/aboutUsMobile.png";

const Description = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.textContainer}>
        <div className={classes.text}>
          <Text type="h3" align="left" weight="600">Nutrición responsable</Text>
          <Text type="h4" margin="30px 0px 0px 0px">
            Somos un equipo de profesionales conformado por nutricionistas y
            licenciados en educación física. De esta manera, podemos brindar a
            nuestros pacientes un abordaje total y específico; enfocándonos en
            cada nivel de necesidad, para lograr resultados efectivos.
          </Text>
          <img className={classes.imageMobile}   src={imageMobile} alt="mobile" />
          <Text type="h4" margin="30px 0px 0px 0px">
            Nutricionalmente, disponemos de diversos tratamientos
            personalizados, como ser: <Text weight="700" type="h4">cambio de hábitos, planes hipocalóricos e
            hipercalóricos, planes enfocados a personas que presentan
            intolerancias, alergias y patologías, así como también planes
            específicos para vegetarianos, niños y adolescentes, mujeres que
            transcurren embarazo o lactancia, entre otros.</Text>
          </Text>
          <Text type="h4" margin="30px 0px 0px 0px">
            En paralelo, la visión aportada a través de nuestros licenciados en
            educación física, ayudará a que el paciente alcance sus objetivos de
            manera más eficiente.
          </Text>
        </div>
      </div>
      <img src={imageMobile} alt="web" className={classes.image} />
    </div>
  );
};

export default Description;
