import { FunctionComponent } from "react";
import { ComponentContext } from "utils/types";

import useStyles from "./styles";

interface TextProps {
  type?: "p" | "h1" | "h2" | "h3" | "h3R" | "h4" | "h5" | "h6";
  weight?: "100" | "200" | "300" | "400" | "500" | "600" | "700" | "800";
  textDecoration?: "underline" | "none";
  align?: "left" | "center" | "right" | "justify";
  margin?: string | number;
  context?: ComponentContext;
}

const Text: FunctionComponent<TextProps> = ({
  weight,
  align = "left",
  type = "p",
  context = "default",
  margin,
  textDecoration,
  children,
  ...rest
}) => {
  const classes = useStyles({
    weight,
    type,
    align,
    context,
    textDecoration,
    margin,
  });
  return (
    <p
      {...rest}
      className={`${classes.text} ${classes[type]} ${
        classes[context as ComponentContext]
      }`}
    >
      {children}
    </p>
  );
};

export default Text;
