import React, { useEffect, useState } from "react";
import { v4 } from "uuid";
import Button from "../Button";
import Input from "../Input";
import Text from "../Text";

import TodosComponent from "./Todos";

const createTodo = (description: string) => {
  const newDate = new Date();

  return {
    localId: v4(),
    user_id: 1,
    created_at: newDate,
    completed_at: null,
    updated_at: newDate,
    description,
    deleted: false,
  };
};

const Todos = ({
  onTodosChange,
  title,
  initialTodos,
  placeholder,
  inputRef,
  onBlur,
  todos: todosProps,
}: {
  onBlur?: React.FocusEventHandler<HTMLInputElement> | undefined;
  inputRef?: React.LegacyRef<HTMLInputElement> | undefined;
  title: string;
  placeholder: string;
  initialTodos?: string[];
  todos?: string[];
  onTodosChange: (todos: TodoType[]) => void;
}) => {
  const [todo, setTodo] = useState("");
  const [todos, setTodos] = useState<TodoType[]>(
    initialTodos?.map((todo) => createTodo(todo)) || []
  );

  useEffect(() => {
    if (todosProps) setTodos(todosProps.map((todo) => createTodo(todo)));
  }, [todosProps]);

  const addTodo = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (todo.length > 0) {
      const todosCopy = [...todos];
      todosCopy.push(createTodo(todo));
      setTodos(todosCopy);
      setTodo("");
      onTodosChange(todosCopy);
    }
  };

  return (
    <form
      style={{
        marginTop: 30,
        flex: 1,
        alignSelf: "stretch",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          marginBottom: 15,
          alignSelf: "stretch",
          flex: 1,
        }}
      >
        <div style={{ flex: 1 }}>
          <Text>{title}</Text>
          <div style={{ display: "flex" }}>
            <Input
              onBlur={onBlur}
              inputRef={inputRef}
              style={{ flex: 1, alignSelf: "stretch" }}
              type="text"
              placeholder={placeholder}
              name="ingredient"
              value={todo}
              onChange={(event) => {
                setTodo(event.target.value);
              }}
            />
            <Button onClick={addTodo}>+</Button>
          </div>
        </div>
      </div>
      <TodosComponent
        todos={todos}
        onClickTodo={() => {}}
        onUpdate={(localId, description) => {
          const todosCopy = [...todos];
          onTodosChange(
            todosCopy.map((todo) =>
              todo.localId === localId ? { ...todo, description } : todo
            )
          );
          setTodos(
            todosCopy.map((todo) =>
              todo.localId === localId ? { ...todo, description } : todo
            )
          );
        }}
        onDeleteTodo={(toDelete) => {
          const todosCopy = [...todos];
          onTodosChange(
            todosCopy.filter((todo) => todo.localId !== toDelete.localId)
          );
          setTodos(
            todosCopy.filter((todo) => todo.localId !== toDelete.localId)
          );
        }}
      />
    </form>
  );
};

export default Todos;
