import useStyles from "./styles";
import Text from "components/common/Text";
import TextIcon from "./TextIcon";
import cutlery from "assets/cutlery.png";
import timer from "assets/timer.png";
import { PDFDownloadLink } from "@react-pdf/renderer";
import MyDocument from "components/RecetaPDF";

const Content = ({ recipe }: { recipe: RecipeType }) => {
  const classes = useStyles();
  const renderPreparation = () => (
    <div>
      <Text type="h4" weight="600" margin="50px 0px 0px 40px">
        Preparación
      </Text>
      {recipe.steps?.map((step, index) => (
        <Text type="p" margin="16px 0px 0px 40px">
          {`${index + 1}- ${step}`}
        </Text>
      ))}
    </div>
  );

  const renderIngredients = () => (
    <div className={classes.ingredients}>
      <Text type="h4" weight="600">
        Info
      </Text>
      <div>
        <TextIcon
          icon={timer}
          text={recipe ? `${recipe?.cookTime} minutos` : ""}
        />
        <TextIcon
          icon={cutlery}
          text={recipe ? `${recipe?.portions} porciones` : ""}
        />
      </div>
      <div>
        <Text type="h4" weight="600" margin="50px 0px 24px 0px">
          Ingredientes
        </Text>
        {recipe.ingredients?.map((ingredient) => (
          <Text type="p" margin="16px 0px 0px 0px" key={ingredient}>
            {ingredient}
          </Text>
        ))}
      </div>
    </div>
  );

  return (
    <div
      className={classes.container}
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div style={{ marginBottom: 18 }}>
        <PDFDownloadLink
          document={<MyDocument recipe={recipe} />}
          fileName={recipe.name}
        >
          Descargar Receta
        </PDFDownloadLink>
      </div>
      <div style={{ display: "flex" }}>
        {renderIngredients()}
        {renderPreparation()}
      </div>
    </div>
  );
};

export default Content;
