import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "..";

interface RecipesSlice {
  recipes: RecipeType[];
  loading: boolean;
  error: string | null;
}

export const initialState: RecipesSlice = {
  recipes: [],
  error: null,
  loading: false,
};

export const recipeSlice = createSlice({
  name: "recipes",
  initialState,
  reducers: {
    getRecipesSuccess: (state, action: PayloadAction<RecipeType[]>) => {
      state.loading = false;
      state.error = null;
      state.recipes = action.payload;
    },
    createRecipeSuccess: (state, action: PayloadAction<RecipeType>) => {
      state.loading = false;
      state.error = null;
      state.recipes.push(action.payload);
    },
    updateRecipeSuccess: (state, action: PayloadAction<RecipeType>) => {
      state.loading = false;
      state.error = null;
      state.recipes = state.recipes.map((recipe) =>
        recipe.id === action.payload.id ? { ...action.payload } : recipe
      );
    },
  },
});

export const {
  getRecipesSuccess,
  createRecipeSuccess,
  updateRecipeSuccess,
} = recipeSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const getRecipeSelector: (
  state: RootState,
  id: string
) => RecipeType | undefined = (state, id) => {
  return state.recipes.recipes.find((recipe) => recipe.id === id);
};

export const getRecipesSelector: (
  state: RootState,
  search: { text?: string; type?: string }
) => RecipeType[] = (state: RootState, search) => {
  return state.recipes.recipes.filter((recipe) => {
    const recipeName = recipe.name
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");

    const hasFilterText = search.text && search.text.length > 0;
    const hasFilterType = search.type && search.type.length > 0;

    if (!hasFilterText && !hasFilterType) return true;
    else if (hasFilterType) {
      return (
        recipe.category === search.type &&
        recipeName.includes(search.text?.toLowerCase() || "")
      );
    }
    return (
      recipeName.includes(search.text?.toLowerCase() || "") ||
      recipe.category.toLowerCase().includes(search.text?.toLowerCase() || "")
    );
  });
};

export const { reducer } = recipeSlice;
