import React, { FunctionComponent, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

import useStyles from "./styles";
import Text from "components/common/Text";
import Card from "components/common/Card";

import SERVICES from "./data";
import closeIcon from "assets/closeIcon.png";

export interface ServiceProps {
  image: string;
  title: string;
  imageAlign?: string;
  showAsModal?: boolean;
  onShowMore?: (service: ServiceProps) => void;
  text: { data: string; key: string }[];
  onClose?: () => void;
}

const Service = ({
  image,
  title,
  text,
  imageAlign,
  showAsModal,
  onShowMore,
  onClose,
}: ServiceProps) => {
  const classes = useStyles();

  const renderService = () => (
    <motion.div
      layout
      className={`${classes.cardContent} ${imageAlign && classes.reverse} ${
        showAsModal && classes.modal
      }`}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <img
        className={showAsModal ? classes.closeIcon : classes.hideCloseIcon}
        onClick={() => {
          if (onClose) {
            onClose();
          }
        }}
        src={closeIcon}
        alt="close icon"
      />
      <img
        src={image}
        className={`${classes.image} ${showAsModal && classes.imageModal}`}
        alt={title}
      />
      <div className={`${classes.textContainer} ${classes.textContainerModal}`}>
        <Text type="h3" weight="600">{title}</Text>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            className={`${classes.text} ${showAsModal && classes.textModal}`}
          >
            {text.map(({ key, data }) => (
              <Text
                key={key}
                type={showAsModal ? "h4" : "h3R"}
                margin="25px 0px 0px 0px"
              >
                {data}
              </Text>
            ))}
          </div>
          {!showAsModal && (
            <div
              className={classes.showMore}
              onClick={() => {
                if (onShowMore)
                  onShowMore({
                    image,
                    title,
                    text,
                    imageAlign,
                    showAsModal,
                    onShowMore,
                  });
              }}
            >
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );

  if (showAsModal)
    return (
      <motion.div
        animate={{
          scale: 1,
          opacity: 1,
          transition: {
            duration: 0.4,
            stiffness: 140,
          },
        }}
        exit={{
          opacity: 0,
          transition: {
            duration: 0.4,
            stiffness: 140,
          },
        }}
        initial={{
          opacity: 0,
          scale: 0,
        }}
        layout
        onClick={() => {
          if (onClose) onClose();
        }}
        className={classes.modalContainer}
      >
        {renderService()}
      </motion.div>
    );

  return <Card margin="45px 0px 45px 0px">{renderService()}</Card>;
};

const Services: FunctionComponent = () => {
  const classes = useStyles();
  const [selectedService, setSelectedService] = useState<ServiceProps | null>(
    null
  );

  return (
    <div className={classes.container}>
      <Service {...SERVICES[0]} onShowMore={setSelectedService} />
      <Service {...SERVICES[1]} onShowMore={setSelectedService} />
      <AnimatePresence>
        {selectedService && (
          <Service
            {...selectedService}
            onShowMore={setSelectedService}
            showAsModal
            onClose={() => setSelectedService(null)}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default Services;
