import { FunctionComponent } from "react";

import Text from "components/common/Text";
import Card from "../Card";
import useStyles from "./styles";

import emailIcon from "assets/emailIcon.png";
import phoneIcon from "assets/phoneIcon.png";
import addressIcon from "assets/addressIcon.png";

interface ContactCardProps {
  icon: string;
  title: string;
  data: string[];
}

const ContactCard: FunctionComponent<ContactCardProps> = ({
  icon,
  data,
  title,
}) => {
  const classes = useStyles();
  return (
    <Card flexDirection={"column"}>
      <img src={icon} alt="icon" className={classes.icon} />
      <div className={classes.cardContainer}>
        <Text weight="600" margin="0px 0px 20px 0px">
          {title}
        </Text>
        {data.map((info, index) => (
          <Text margin="0px 0px 10px 0px" key={index}>
            {info}
          </Text>
        ))}
      </div>
    </Card>
  );
};

const Content = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <ContactCard
        icon={phoneIcon}
        data={["094 580 880", "2618 2054"]}
        title="Teléfono"
      />
      <ContactCard
        icon={addressIcon}
        data={["Valiente 4858  esq.", "Valencia, Montevideo"]}
        title="Dirección"
      />
      <ContactCard
        icon={emailIcon}
        data={["cnutricionalmlama@gmail.com"]}
        title="Email"
      />
    </div>
  );
};

export default Content;
