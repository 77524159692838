import { FC } from "react";

import MealComponent from "./Meal";

interface MealProps {
  meal: MealType;
  onMealChange: (meal: MealType) => void;
}

const Meal: FC<MealProps> = ({ meal, onMealChange }) => {
  return (
    <MealComponent
      mealName={meal.mealName}
      food={meal.food}
      onMealChange={onMealChange}
    />
  );
};

export default Meal;
