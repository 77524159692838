import useStyles from "./styles";
import Credentials from "../Credentials";
import Text from "components/common/Text";

const Content = ({
  login,
  error,
}: {
  error: string | undefined;
  login: ({ email, password }: { email: string; password: string }) => void;
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.overlay}>
        <Text type="h4" align="center">
          INGRESÁ AL
        </Text>
        <Text type="h2" align="center" margin="0px 0px 20px 0px">
          Recetario
        </Text>
        <Credentials onLogin={login} error={error} />
      </div>
    </div>
  );
};

export default Content;
