import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeType) => ({
  container: {
    background: theme.palette.primary.light,
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },

  ingredients: {
    backgroundColor: theme.palette.white.main,
    display: "flex",
    borderRadius: "20px 20px 20px 20px",
    width: "20%",
    padding: 40,
    flexDirection: "column",
    marginBottom: 40,
  },

  content: {
    flexDirection: "row",
    display: "flex",
  },

  "@media (max-width: 750px)": {
    container: {
      paddingTop: 43,
      paddingBottom: 43,
    },
  },
  "@media (max-width: 855px)": {
    container: {
      flexDirection: "column",
    },

    ingredients: {
      backgroundColor: theme.palette.white.main,
      borderRadius: "20px 20px 20px 20px",
      alignSelf: "center",
      width: "80%",
      display: "flex",
      flexDirection: "column",
    },
    content: {
      flexDirection: "column",
      display: "flex",
    },
  },
}));

export default useStyles;
