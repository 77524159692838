import { FunctionComponent } from "react";

import useStyles from "./styles";

interface CardProps {
  flexDirection?: "column" | "row";
}

const Card: FunctionComponent<CardProps> = ({ children, flexDirection }) => {
  const classes = useStyles();

  return (
    <div className={classes.container} style={{ flexDirection }}>
      {children}
    </div>
  );
};

export default Card;
