import { FC, useState } from "react";
import { DayType, MealName } from "utils/types";
import MenuComponent from "./Menu";

interface MenuProps {
  menu: MenuType;
  status?: StatusType;
  onAddMenu: (menu: MenuType) => void;
}

export const emptyMenu = (day: DayType) => {
  const emptyMeal: (meal: MealName) => MealType = (meal) => ({
    food: [],
    mealName: meal,
  });

  return {
    day,
    meals: [
      emptyMeal("Desayuno"),
      emptyMeal("Almuerzo"),
      emptyMeal("Merienda"),
      emptyMeal("Cena"),
      emptyMeal("Colacion"),
    ],
  };
};

const isEmptyMenu = (menu: MenuType) => {
  return !menu.meals.some((meal) => meal.food.length);
};

const Menu: FC<MenuProps> = ({ onAddMenu, menu, status }) => {
  const [currentMenu, setCurrentMenu] = useState(menu);
  return (
    <MenuComponent
      progress={isEmptyMenu(menu) ? "Vacia" : "En progreso"}
      status={status}
      menu={currentMenu}
      onMenuChange={(menu) => {
        setCurrentMenu(menu);
      }}
      onAddMenu={onAddMenu}
    />
  );
};

export default Menu;
