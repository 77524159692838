import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeType) => ({
  container: {
    borderRadius: 6,
    fontSize: 14,
    width: (props) => (props.width ? props.width : "fit-content"),
    padding: "12px 32px 12px 32px",
    fontFamily: "Lato",
    "-webkit-tap-highlight-color": "transparent",
    margin: (props) =>
      props.margin !== undefined ? props.margin : "0px 10px 0px 10px",
    cursor: "pointer",
    alignItems: "center",
    "&:focus": {
      outline: "none",
    },
    color: (props) => {
      switch (props.context) {
        case "primary":
          return theme.palette.white.main;
        case "secondary":
          return theme.palette.white.main;
        case "info":
          return theme.palette.black.main;
        case "error":
          return theme.palette.red.dark;
        case "default":
          return theme.palette.primary.dark;
      }
    },
    backgroundColor: (props) => {
      switch (props.context) {
        case "primary":
          return theme.palette.primary.dark;
        case "secondary":
          return theme.palette.secondary.main;
        case "info":
          return "transparent";
        case "default":
          return "transparent";
      }
    },
    border: (props) => {
      switch (props.context) {
        case "primary":
          return `1px solid ${theme.palette.primary.dark}`;
        case "secondary":
          return `1px solid ${theme.palette.secondary.dark}`;
        case "info":
          return 0;
        case "error":
          return `1px solid ${theme.palette.red.dark}`;
        case "default":
          return `1px solid ${theme.palette.primary.dark}`;
      }
    },
    fontWeight: (props) => {
      if (props.context === "info") {
        return props.isActive ? 700 : 500;
      }
    },
  },

  small: {},

  medium: {
    width: 183,
    height: 47,
  },

  large: {},
}));

export default useStyles;
