import {
  Page,
  Image,
  Text,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import { FC } from "react";
import logo1 from "assets/logo1.png";

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    padding: 36,
  },
  section: {
    marginBottom: 10,
    flexGrow: 1,
  },
});

interface RecipePDFProps {
  recipe: RecipeType;
}

// Create Document Component
const MyDocument: FC<RecipePDFProps> = ({ recipe }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            alignSelf: "flex-end",
            fontSize: 22,
            fontWeight: "bold",
            marginBottom: 8,
          }}
        >
          {recipe.name}
        </Text>
        <Image
          src={logo1}
          style={{ width: 150, height: "auto", marginTop: 20, marginRight: 10 }}
        ></Image>
      </View>

      <View style={styles.section}>
        <View
          style={{
            paddingVertical: 10,
            marginVertical: 6,
          }}
        >
          <Text style={{ fontSize: 16, fontWeight: "bold", marginBottom: 8 }}>
            Información
          </Text>
          <Text style={{ fontSize: 12 }}>{recipe.cookTime} minutos</Text>
          <Text style={{ fontSize: 12 }}>{recipe.portions} porciones</Text>
        </View>
        <View
          style={{
            paddingVertical: 10,
            marginVertical: 6,
          }}
        >
          <Text style={{ fontSize: 16, fontWeight: "bold", marginBottom: 8 }}>
            Ingredientes
          </Text>
          {recipe.ingredients.map((ingredient) => (
            <Text style={{ fontSize: 12, marginBottom: 4 }}>
              • {ingredient}
            </Text>
          ))}
        </View>
        <View
          style={{
            paddingVertical: 10,
            marginVertical: 6,
          }}
        >
          <Text style={{ fontSize: 16, fontWeight: "bold", marginBottom: 8 }}>
            Preparación
          </Text>
          {recipe.steps.map((step) => (
            <Text style={{ fontSize: 12, marginBottom: 4 }}>• {step}</Text>
          ))}
        </View>
      </View>
    </Page>
  </Document>
);

export default MyDocument;
