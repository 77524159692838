import SectionHeader from "components/common/SectionHeader";
import SectionContent from "components/common/SectionContent";
import { FunctionComponent } from "react";
import theme from "utils/theme";

interface SectionContainerProps {
  title?: string;
  subtitle?: string;
  hideHeader?: boolean;
  style?: React.CSSProperties,
}

const SectionContainer: FunctionComponent<SectionContainerProps> = ({
  title,
  subtitle,
  children,
  style,
  hideHeader = false,
}) => {
  return (
    <div
      style={{
        backgroundColor: theme.palette.background.light,
        minHeight: "calc(100vh - 83px)",
        ...style
      }}
    >
      {!hideHeader && title && <SectionHeader title={title} subtitle={subtitle} />}
      <SectionContent>{children}</SectionContent>
    </div>
  );
};

export default SectionContainer;
