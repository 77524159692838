import { createContext, FunctionComponent, useEffect, useState } from "react";

import { GET_PROFILE, LOGIN } from "services/User";
import { useMutation, useQuery } from "@apollo/client";

const days = ["Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado", "Domingo"];

interface AuthContextType {
  user: UserType | null;
  logout: () => void;
  error: undefined | string;
  isLoading: boolean;
  login: (email: string, password: string) => void;
}

export const AuthContext = createContext<AuthContextType>({
  user: null,
  isLoading: false,
  error: undefined,
  logout: () => {},
  login: () => {},
});

const AuthProvider: FunctionComponent = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<UserType | null>(null);
  const [login, responseLogin] = useMutation(LOGIN);
  const currentUserResponse = useQuery(GET_PROFILE);

  useEffect(() => {
    if (currentUserResponse.data?.profile)
      setCurrentUser({
        ...currentUserResponse.data.profile,
        menus: currentUserResponse.data.profile.menus.slice().sort(
          (a: MenuType, b: MenuType) =>
            days.findIndex((day) => day === a.day) -
            days.findIndex((day) => day === b.day)
        ),
        id: currentUserResponse.data.profile.id,
      });
    else {
      setCurrentUser(null);
    }
  }, [currentUserResponse]);

  return (
    <AuthContext.Provider
      value={{
        user: currentUser,
        login: async (email, password) => {
          try {
            const res = await login({ variables: { email, password } });
            localStorage.setItem("token", res.data.login);
            currentUserResponse.refetch();
          } catch {}
        },
        isLoading: responseLogin.loading,
        error: responseLogin.error?.message,
        logout: () => {
          try {
            localStorage.removeItem("token");
            currentUserResponse.refetch();
          } catch {}
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
