import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeType) => ({
  container: {
    display: "flex",
    background: theme.palette.background.dark,
    height: "calc(100vh - 83px)",
    marginTop: 83,
    flexDirection: "column",
    justifyContent: "center",
    paddingLeft: 220,
  },

  image: {
    position: "absolute",
    right: 0,
    bottom: 0,
    maxHeight: "calc(100% - 113px)",
    width: "auto",
  },

  triangle: {
    height: "calc(100% - 83px)",
    right: 0,
    bottom: 0,
    position: "absolute",
  },

  data: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: 200,
    maxWidth: 500,
  },

  "@media (max-width: 830px)": {
    image: { display: "none" },

    triangle: { display: "none" },

    container: {
      padding: 0,
      alignItems: "center",
    },
  },
}));

export default useStyles;
