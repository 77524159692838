import { motion } from "framer-motion";
import { FunctionComponent } from "react";
import useStyles from "./styles";

const Card: FunctionComponent<{
  margin?: number | string;
  img: string;
  title: string;
  cursor?: string;
  animated?: boolean;
  isSelected?: boolean;
  onClick?: () => void;
}> = ({
  children,
  margin,
  img,
  title,
  cursor,
  animated = false,
  isSelected,
  onClick,
}) => {
  const classes = useStyles({ margin, cursor });
  return (
    <motion.div
      onClick={onClick}
      animate={{
        opacity: 1,
        transition: {
          stiffness: 150,
          duration: 0.5,
        },
      }}
      initial={{
        opacity: 0,
      }}
      whileHover={
        animated ? { scale: 1.05, y: -10, transition: { stiffness: 200 } } : {}
      }
      className={`${classes.container} ${isSelected && classes.selected}`}
    >
      <img src={img} alt="icon" className={classes.image} />
      {children}
    </motion.div>
  );
};

export default Card;
