import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeType) => ({
  container: {
    marginTop: 55,
  },

  cardContent: {
    display: "flex",
    position: 'relative',
  },

  reverse: {
    flexDirection: "row-reverse",
  },

  modalContainer: {
    position: "fixed",
    height: "100vh",
    width: "100vw",
    top: 0,
    left: 0,
    overflow: 'scroll',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 100,
    backgroundColor: "rgba(0,0,0,0.5)",
  },

  modal: {
    flexDirection: "row",
    maxWidth: 1000,
    boxShadow: "0px 4px 12px 8px rgba(0, 0, 0, 0.05)",
    borderRadius: 7,
    backgroundColor: theme.palette.white.main,
    overflow: "scroll",
  },

  image: {},

  imageModal: {},

  textContainerModal: {},

  textContainer: { display: "flex", flexDirection: "column", margin: 34 },

  showMore: {
    display: "none",
    cursor: "pointer",
  },

  text: {
    display: "flex",
    flexDirection: "column",
  },

  hideCloseIcon: {
    display: 'none',
  },

  closeIcon: {
    position: 'absolute',
    width: 20,
    top: 15,
    right: 15,
    marginBottom: 15,
    cursor: 'pointer',
    padding: 10,
  },

  textModal: {},

  "@media (max-width: 990px)": {
    image: {
      width: 272,
      objectFit: "cover",
      height: "auto",
    },
  },

  "@media (max-width: 740px)": {
    closeIcon: {
      display: 'flex',
    },

    showMore: {
      display: "block",
      alignSelf: "flex-end",
      marginTop: 15,
    },

    text: {
      display: "none",
    },

    textModal: {
      display: "flex",
      flexDirection: 'column',
    },

    textContainer: {
      margin: 0,
      padding: 20,
      maxWidth: 210,
      "& span": {
        textAlign: "center",
      },
    },

    textContainerModal: {
      maxWidth: 'unset'
    },

    image: {
      alignSelf: "stretch",
    },

    imageModal: {
      display: 'none',
    },

    cardContent: {
      flexDirection: "column",
    },

    modal: {
      width: '100%',
    },

    container: {
      maxWidth: 272,
      alignSelf: 'center'
    },
  },

  "@media (max-width: 500px)": {
    modal: {
      height: '100%',
      margin: 0,
    }
  }
}));

export default useStyles;
