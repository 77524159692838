import { motion } from "framer-motion";
import { FunctionComponent } from "react";
import useStyles from "./styles";

const Card: FunctionComponent<{
  margin?: number | string;
  title: string;
  cursor?: string;
  animated?: boolean;
  onClick?: () => void;
}> = ({ children, margin, title, cursor, animated = false, onClick }) => {
  const classes = useStyles({ margin, cursor });
  return (
    <motion.div
      onClick={onClick}
      animate={{
        opacity: 1,
        transition: {
          stiffness: 150,
          duration: 0.5,
        },
      }}
      initial={{
        opacity: 0,
      }}
      whileHover={
        animated ? { scale: 1.05, y: -10, transition: { stiffness: 200 } } : {}
      }
      className={classes.container}
    >
      {children}
    </motion.div>
  );
};

export default Card;
