import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "..";

interface UsersSlice {
  users: UserType[];
  loading: boolean;
  error: string | null;
  selectedUser?: UserType;
}

export const initialState: UsersSlice = {
  users: [],
  error: null,
  loading: false,
};

export const usersSlice = createSlice({
  name: "recipes",
  initialState,
  reducers: {
    getUsersSuccess: (state, action: PayloadAction<UserType[]>) => {
      state.loading = false;
      state.error = null;
      state.users = action.payload;
    },
    getUserSuccess: (state, action: PayloadAction<UserType | undefined>) => {
      state.loading = false;
      state.error = null;
      state.users = state.users.map((user) =>
        user.id === action.payload?.id ? action.payload : user
      );
      state.selectedUser = action.payload;
    },
    createUserSuccess: (state, action: PayloadAction<UserType>) => {
      state.loading = false;
      state.error = null;
      state.users = [...state.users, action.payload];
    },
    deleteUserSuccess: (state, action: PayloadAction<string>) => {
      state.users = state.users.filter((user) => {
        return user.id !== action.payload;
      });
    },
  },
});

export const {
  getUsersSuccess,
  createUserSuccess,
  getUserSuccess,
  deleteUserSuccess,
} = usersSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const getUsersSelector: (state: RootState) => UserType[] = (
  state: RootState
) => {
  return state.users.users;
};

export const getUserSelector: (
  state: RootState,
  userId: string
) => UserType | undefined = (state, userId) => {
  return state.users.users.find((user) => user.id === userId);
};

export const { reducer } = usersSlice;
