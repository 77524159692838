import { LottieOptions, useLottie } from "lottie-react";

import loading from "assets/loading.json";

const Loading = () => {
  const options: LottieOptions = {
    animationData: loading,
    loop: true,
    autoplay: true,
    style: {
      width: 150,
      alignSelf: 'center'
    }
  };

  const { View } = useLottie(options);

  return View;
};

export default Loading;
