import { FunctionComponent, useState } from "react";
import { AnimatePresence, AnimateSharedLayout, motion } from "framer-motion";

import Card from "components/common/Card";
import useStyles from "./styles";
import Text from "components/common/Text";

const Service: FunctionComponent<{
  service: ServiceType;
  onReadMore: (service: ServiceType) => void;
}> = ({ service }) => {
  const classes = useStyles();
  return (
    <Card margin={17} cursor="pointer">
      <motion.div layout className={classes.serviceContainer}>
        {service.icon && (
          <div className={classes.serviceIcon}>
            <img src={service.icon} alt="icon" />
          </div>
        )}
        <Text weight="700" align="center" margin={10}>
          {service.title}
        </Text>
        <Text align="center" margin={10}>
          {service.text}
        </Text>
      </motion.div>
    </Card>
  );
};

const Services: FunctionComponent<{ services: ServiceType[] }> = ({
  services,
}) => {
  const [selectedService, setSelectedService] = useState<ServiceType | null>(
    null
  );
  const classes = useStyles();
  return (
    <AnimateSharedLayout>
      <div className={classes.container}>
        {services.map((service) => (
          <Service
            key={service.title}
            service={service}
            onReadMore={setSelectedService}
          />
        ))}
      </div>
      <AnimatePresence>{selectedService && <div></div>}</AnimatePresence>
    </AnimateSharedLayout>
  );
};

export default Services;
