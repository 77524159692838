import { DefaultTheme } from "react-jss";

const theme: ThemeType = {
  palette: {
    primary: {
      light: "#F6F3FF",
      main: "rgba(203, 182, 255, 0.6)",
      dark: "#987BEF",
    },
    secondary: {
      light: "rgba(170, 237, 209, 0.24)",
      main: "#94E4C3",
      dark: "#94E4C3",
    },
    black: {
      light: "#rgb(171 162 162)",
      main: "#000000",
      dark: "#000000",
    },
    white: {
      light: "#ffffff",
      main: "#ffffff",
      dark: "#E5E5E5",
    },
    background: {
      light: "#F6F3FF",
      main: "#F1ECFF",
      dark: "linear-gradient(180deg, #CBB6FF 0%, #987BEF 100%, #E1D5FF 100%)",
    },
    red: {
      light: "#cc2121",
      main: "#cc2121",
      dark: "#cc2121",
    },
  },
};

interface ColorType {
  light: string;
  main: string;
  dark: string;
}

declare global {
  interface ThemeType extends DefaultTheme {
    palette: {
      primary: ColorType;
      secondary: ColorType;
      black: ColorType;
      white: ColorType;
      background: ColorType;
      red: ColorType;
    };
  }
}

export default theme;
