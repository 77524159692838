import { useCallback, useMemo, useState } from "react";
import { motion } from "framer-motion";
import { isBrowser, isMobile } from "react-device-detect";

import useStyles from "./styles";

import { ReactComponent as ReactLogo } from "assets/trashIcon.svg";
import theme from "utils/theme";

interface TodoProps {
  todo: TodoType;
  onUpdate: (localId: string, description: string) => void;
  onDelete: (todo: TodoType) => void;
  onClick: (todo: TodoType) => void;
}

function Todo({ todo, onDelete, onUpdate }: TodoProps) {
  const classes = useStyles();
  const [isEditing, setIsEditing] = useState(false);
  const [editDescription, setEditDescription] = useState("");
  const [isHover, setIsHover] = useState(false);

  const showDelete = useMemo(() => (isBrowser && isHover) || isMobile, [
    isHover,
  ]);

  const onSubmit = useCallback(() => {
    if (editDescription.length > 0) {
      onUpdate(todo.localId, editDescription);
    } else {
      onDelete(todo);
    }
    setIsEditing(false);
    setEditDescription("");
  }, [editDescription, onUpdate, onDelete, todo]);

  return (
    <motion.div
      className={classes.container}
      layout
      transition={{
        type: "spring",
        damping: 20,
        stiffness: 300,
      }}
      onMouseOver={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
    >
      <div className={classes.todo}>
        {isEditing ? (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit();
            }}
            className={classes.form}
          >
            <input
              ref={(input) => {
                input?.focus();
              }}
              onBlur={() => {
                setIsEditing(false);
                onSubmit();
              }}
              className={`${classes.inputDescription} `}
              onChange={(e) => {
                setEditDescription(e.target.value);
              }}
              value={editDescription}
            />
          </form>
        ) : (
          <p
            onClick={() => {
              setIsEditing(true);
              setEditDescription(todo.description);
            }}
            className={`${classes.description}`}
          >
            {todo.description}
          </p>
        )}
      </div>
      <button
        className={classes.delete}
        onClick={() => {
          onDelete(todo);
        }}
      >
        {showDelete && (
          <ReactLogo style={{ width: 20, height: 20, fill: theme.palette.primary.dark }} />
        )}
      </button>
    </motion.div>
  );
}

export default Todo;
