import {
  Page,
  Text,
  Image,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import { FC } from "react";
import logo1 from "assets/logo1.png";

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    paddingVertical: 10,
    paddingHorizontal: 20,
  },
  section: {
    marginBottom: 10,
    flexGrow: 1,
  },
});

interface MenuPDFProps {
  menus: MenuType[];
}

const Meal: FC<{ meal: MealType }> = ({ meal }) => {
  return (
    <View
      style={{
        border: "0.5 solid gray",
        width: 105,
        height: 91,
        display: "flex",
        flexDirection: "column",
        padding: 5,
        marginRight: 8,
        marginBottom: 2,
      }}
    >
      {meal.food.map((food) => (
        <Text style={{ fontSize: 9 }}>• {food}</Text>
      ))}
    </View>
  );
};

const Meals: FC<{ meals: MealType[] }> = ({ meals }) => {
  return (
    <View
      style={{ display: "flex", flexDirection: "column", flexWrap: "wrap" }}
    >
      {meals.map(({ mealName, food }) => (
        <Meal
          meal={{
            mealName,
            food,
          }}
        />
      ))}
    </View>
  );
};

const Menu: FC<{ menu: MenuType }> = ({ menu }) => {
  return (
    <View style={{ display: "flex", flexDirection: "column" }}>
      <Text style={{ fontSize: 14, fontWeight: "bold", marginBottom: 10 }}>
        {menu.day}
      </Text>
      <Meals meals={menu.meals} />
    </View>
  );
};

const comidas = ["Desayuno", "Almuerzo", "Merienda", "Cena", "Colación"];
// Create Document Component
const MyDocument: FC<MenuPDFProps> = ({ menus }) => (
  <Document>
    <Page size="A4" style={styles.page} orientation="landscape">
      <View style={{ display: "flex" }}>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Text
            style={{
              fontSize: 20,
              fontWeight: "bold",
              marginBottom: 20,
            }}
          >
            Menú Semanal
          </Text>
          <Image
            src={logo1}
            style={{
              width: 150,
              height: "auto",
              marginVertical: 20,
              marginRight: 10,
            }}
          ></Image>
        </View>

        <View style={{ display: "flex", flexDirection: "row" }}>
          <View style={{ display: "flex" }}>
            <Text
              style={{
                fontSize: 14,
                color: "white",
                fontWeight: "bold",
                marginBottom: 20,
              }}
            >
              Comida
            </Text>

            {comidas.map((comida) => (
              <View
                style={{
                  fontWeight: "bold",
                  height: 90,
                  paddingVertical: 5,
                  marginRight: 8,
                }}
              >
                <Text style={{ fontSize: 10, fontWeight: "bold" }}>
                  {comida}
                </Text>
              </View>
            ))}
          </View>

          <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            {menus.map((menu) => (
              <Menu menu={menu} />
            ))}
          </View>
        </View>
      </View>
    </Page>
  </Document>
);

export default MyDocument;
