import { ServiceProps } from ".";

import healthyNutrition from "assets/healthyNutrition.png";
import customStudies from "assets/customStudies.png";

const SERVICES: ServiceProps[] = [
  {
    image: healthyNutrition,
    title: "Alimentación saludable",
    text: [
      {
        key: "1",
        data:
          "Sabemos que no siempre es sencillo establecer una rutina alimenticia saludable sin un completo recetario, debidamente estudiado, que facilite durante el desarrollo de cualquiera de los tratamientos una continuidad de platos disfrutables y de práctica elaboración.",
      },
      {
        key: "2",
        data:
          "Es por esto, que nuestros pacientes tendrán a su disposición un amplio recetario, funcional a cada situación particular, para que sus comidas sean saludables y variadas.",
      },
    ],
  },
  {
    image: customStudies,
    title: "Estudios personalizados",
    imageAlign: 'right',
    text: [
      {
        key: "1",
        data:
          "También ofrecemos la realización de estudio de biompedancia, para conocer porcentajes de masa grasa, masa muscular y gasto energético basal de cada individuo y estudio de antropometría.",
      },
    ],
  },
];

export default SERVICES;
