import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeType) => ({
  container: {
    backgroundColor: theme.palette.white.main,
    borderRadius: 6,
    border: `1px solid #F0F0F0`,
    fontSize: 16,
    color: theme.palette.black.main,
    padding: 8,
    paddingTop: 13,
    paddingBottom: 13,
    fontFamily: "Lato",
    alignSelf: 'stretch'
  },
}));

export default useStyles;
