import { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";

import useStyles from "./styles";
import Text from "components/common/Text";
import Input from "components/common/Input";
import Todos from "components/common/Todos";
import Button from "components/common/Button";
import { ComponentContext } from "utils/types";
import Loading from "components/common/Loading";

interface NewRecipeProps {
  createRecipe: (recipe: RecipeType) => void;
  updateRecipe: (recipe: RecipeType) => void;
  loading: boolean;
  recipe?: RecipeType;
}

const NewRecipe = ({
  createRecipe,
  loading,
  recipe,
  updateRecipe,
}: NewRecipeProps) => {
  const classes = useStyles();
  const stepRef = useRef<HTMLInputElement | null>();
  const { register, handleSubmit, reset } = useForm({ defaultValues: recipe });
  const [ingredients, setIngredients] = useState<string[]>(
    recipe?.ingredients || []
  );
  const [steps, setSteps] = useState<string[]>(recipe?.steps || []);

  const onTodosChange = useCallback((todos) => {
    setIngredients(todos.map((todo: TodoType) => todo.description));
  }, []);

  const onStepsChange = useCallback((todos) => {
    setSteps(todos.map((todo: TodoType) => todo.description));
  }, []);

  useEffect(() => {
    if (recipe)
      reset({
        name: recipe.name,
        portions: recipe.portions,
        category: recipe.category,
        cookTime: recipe.cookTime,
      });
  }, [recipe, reset]);

  if (loading) return <Loading />;

  return (
    <div className={classes.container}>
      <form
        onSubmit={handleSubmit((formData) => {
          if (recipe) {
            updateRecipe({
              ...formData,
              id: recipe.id,
              calories: Number(formData.calories),
              cookTime: Number(formData.cookTime),
              portions: Number(formData.portions),
              ingredients,
              steps,
            });
          } else
            createRecipe({
              ...formData,
              calories: Number(formData.calories),
              cookTime: Number(formData.cookTime),
              portions: Number(formData.portions),
              ingredients,
              steps,
            } as RecipeType);
        })}
      >
        <div className={classes.row}>
          <div style={{ flex: 1, paddingRight: 50 }}>
            <Text>Nombre Receta</Text>
            <Input
              style={{ width: "100%" }}
              type="text"
              placeholder="Nombre"
              name="name"
              onChange={() => {}}
              inputRef={register}
            />
          </div>
          <div style={{ flex: 1, marginLeft: 50 }}>
            <Text>Categoría</Text>
            <select
              defaultValue="Choose..."
              name="category"
              style={{
                padding: "13px 8px",
                width: "100%",
                border: "1px solid #F0F0F0",
                borderRadius: 6,
              }}
              ref={register}
            >
              <option>Seleccionar...</option>
              <option>Carne</option>
              <option>Vegetales</option>
              <option>Tartas</option>
              <option>Postres</option>
              <option>Meriendas</option>
            </select>
          </div>
        </div>

        <div className={classes.row}>
          <div style={{ flex: 1, paddingRight: 50 }}>
            <Text>Porciones</Text>
            <Input
              style={{ width: "100%" }}
              type="number"
              onChange={() => {}}
              placeholder="0"
              inputRef={register}
              name="portions"
            />
          </div>
          <div style={{ flex: 1 }}>
            <Text>Tiempo de cocción</Text>
            <Input
              style={{ width: "100%" }}
              onChange={() => {}}
              type="number"
              placeholder="0"
              inputRef={register}
              name="cookTime"
            />
          </div>
        </div>
        <div className={classes.row}>
          <div style={{ flex: 1 }}>
            <Todos
              onBlur={() => {
                if (stepRef.current) stepRef.current.focus();
              }}
              initialTodos={ingredients}
              onTodosChange={onTodosChange}
              title="Ingredientes"
              placeholder={"Ingrese ingrediente..."}
            />
          </div>
          <div style={{ flex: 1 }}>
            <Todos
              inputRef={(ref) => {
                stepRef.current = ref;
              }}
              initialTodos={steps}
              onTodosChange={onStepsChange}
              title="Preparacion"
              placeholder={"Ingrese paso"}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingTop: 20,
          }}
        >
          <Button
            context={ComponentContext.PRIMARY}
            onClick={() => {}}
            margin="40px 0px 40px 0px"
          >
            {recipe ? "Editar" : "Agregar"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default NewRecipe;
