import { ApolloQueryResult } from "@apollo/client";
import { useCreateRecipe, useUpdateRecipe } from "services/Recipes";
import NewRecipe from "./Content";

const NewRecipeContainer = ({
  recipe,
  refetch,
}: {
  recipe?: RecipeType;
  refetch?: (
    variables?:
      | Partial<{
          id: string;
        }>
      | undefined
  ) => Promise<ApolloQueryResult<any>>;
}) => {
  const [createRecipe, { loading }] = useCreateRecipe();
  const [updateRecipe, { loading: loadingUpdate }] = useUpdateRecipe();

  return (
    <NewRecipe
      updateRecipe={async (updatedRecipe) => {
        try {
          await updateRecipe({ variables: { ...updatedRecipe } });
        } catch {}
      }}
      recipe={recipe}
      loading={loading || loadingUpdate}
      createRecipe={async (recipe) => {
        try {
          await createRecipe({ variables: { ...recipe } });
        } catch {}
      }}
    />
  );
};

export default NewRecipeContainer;
