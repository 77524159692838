import "firebase/auth";
import useStyles from "./styles";
import Input from "components/common/Input";

import { useForm } from "react-hook-form";
import Button from "components/common/Button";
import { ComponentContext } from "utils/types";
import Text from "components/common/Text";

const styles = {
  input: { margin: 20, marginLeft: 0, marginRight: 0 },
};

const Credentials = ({
  onLogin,
  error,
}: {
  error: string | undefined;
  onLogin: ({ email, password }: { email: string; password: string }) => void;
}) => {
  const classes = useStyles();
  const { register, handleSubmit } = useForm();

  return (
    <form className={classes.container}>
      <Input
        placeholder="Email"
        name="email"
        autoComplete="username"
        inputRef={register}
        onChange={() => {}}
        style={styles.input}
      />
      <Input
        placeholder="Clave"
        name="password"
        type="password"
        autoComplete="current-password"
        inputRef={register}
        onChange={() => {}}
        style={styles.input}
      />
      {error && (
        <Text type="h5" context={ComponentContext.ERROR}>
          {error}
        </Text>
      )}
      <Button
        margin="25px 0px 50px 0px"
        text="Ingresar"
        size="medium"
        width="100%"
        onClick={handleSubmit(onLogin)}
        context={ComponentContext.PRIMARY}
      ></Button>
    </form>
  );
};

export default Credentials;
