import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeType) => ({
  container: {
    borderRadius: 7,
    margin: (props) => props.margin,
    cursor: (props) => props.cursor,
    "-webkit-tap-highlight-color": "transparent",
    overflow: "hidden",
    backgroundColor: theme.palette.white.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "20px 30px 20px 30px",
    flex: 1,
    minWidth: 200,
  },

  editButton: {
    display: "flex",
    padding: 0,
    background: "transparent",
    border: 0,
    cursor: "pointer",
    minWidth: 24,
    marginTop: 8,
    minHeight: 24,
    "&:focus": {
      outline: "none",
    },
    "&:active": {
      outline: "none",
    },
  },

  "@media (max-width: 500px)": {
    container: { margin: (props) => (props.margin ? 15 : 0) },
  },
}));

export default useStyles;
