import { FC, useCallback, useState } from "react";
import Input from "components/common/Input";
import Text from "components/common/Text";
import { ComponentContext, DayType } from "utils/types";
import { Modal } from "@material-ui/core";

import Menu, { emptyMenu } from "../Menu";
import Button from "components/common/Button";
import theme from "utils/theme";
import { ReactComponent as InProgressIcon } from "assets/inProgressIcon.svg";
import { ReactComponent as AlertIcon } from "assets/alertIcon.svg";
import { motion } from "framer-motion";
import { useDeleteUser, useUpdateUser } from "services/User";
import { useHistory } from "react-router";

const initialMenuState = [
  emptyMenu("Lunes"),
  emptyMenu("Martes"),
  emptyMenu("Miercoles"),
  emptyMenu("Jueves"),
  emptyMenu("Viernes"),
  emptyMenu("Sabado"),
  emptyMenu("Domingo"),
];

interface UserDetailProps {
  user: UserType;
}

interface ContainerProps {
  style?: React.CSSProperties | undefined;
}

const Container: FC<ContainerProps> = ({ children, style }) => {
  return (
    <div
      style={{
        flex: 1,
        padding: 10,
        display: "flex",
        flexDirection: "column",
        ...style,
      }}
    >
      {children}
    </div>
  );
};

interface FormInputProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  value: string;
  label: string;
  disabled?: boolean;
}

const FormInput: FC<FormInputProps> = ({
  value,
  placeholder,
  label,
  onChange,
  disabled,
}) => {
  return (
    <div style={{ alignSelf: "stretch" }}>
      <Text>{label}</Text>
      <Input
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
      />
    </div>
  );
};

const isEmptyMenu = (menu: MenuType) => {
  return !menu.meals.some((meal) => meal.food.length);
};

const UserDetail: FC<UserDetailProps> = ({ user }) => {
  const [currentUser, setCurrentUser] = useState<UserType>(user);
  const [addMenuLoading, setAddMenuLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [day, setDay] = useState<DayType>();
  const [menus, setMenus] = useState<MenuType[]>(
    user.menus?.length ? user.menus : initialMenuState
  );
  const history = useHistory();
  const [updateUser, { loading }] = useUpdateUser(() => {
    history.goBack();
  });

  const [deleteUser, { loading: loadingDelete }] = useDeleteUser(() => {
    history.goBack();
  });

  const { displayName, email, role } = currentUser;

  const setMenusAndSort = useCallback(
    (callback: (menus: MenuType[]) => MenuType[]) => {
      setMenus((prevMenus) =>
        callback(prevMenus).sort((menu1, menu2) =>
          isEmptyMenu(menu1) && isEmptyMenu(menu2)
            ? 0
            : isEmptyMenu(menu1) && !isEmptyMenu(menu2)
            ? -1
            : 1
        )
      );
    },
    []
  );

  return (
    <div style={{ display: "flex" }}>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        style={{
          overflow: "scroll",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div>
          {day ? (
            <Menu
              status={{ loading: addMenuLoading, error: null }}
              menu={menus.find((menu) => menu.day === day) || emptyMenu(day)}
              onAddMenu={(newMenu) => {
                setAddMenuLoading(true);
                setTimeout(() => {
                  setOpen(false);
                  setAddMenuLoading(false);
                  setMenusAndSort((prevMenus) =>
                    prevMenus.map((menu) =>
                      menu.day === newMenu.day ? newMenu : menu
                    )
                  );
                }, 700);
              }}
            />
          ) : (
            <div></div>
          )}
        </div>
      </Modal>
      <Container>
        <Text type="h3" weight="500">
          Datos básico
        </Text>
        <form style={{ flex: 1, marginTop: 30 }}>
          <FormInput
            onChange={(event) => {
              setCurrentUser((prev) => ({
                ...prev,
                displayName: event.target.value,
              }));
            }}
            label="Nombre Completo"
            placeholder="Nombre Completo"
            value={displayName}
          />
          <FormInput
            onChange={(event) => {
              setCurrentUser((prev) => ({
                ...prev,
                email: event.target.value,
              }));
            }}
            label="Email"
            disabled
            placeholder="Email"
            value={email}
          />

          <FormInput
            onChange={(event) => {
              setCurrentUser((prev) => ({
                ...prev,
                role: event.target.value as "admin" | "user",
              }));
            }}
            label="Tipo de usuario (admin o user)"
            placeholder="Role"
            value={role}
          />
        </form>
      </Container>
      <Container style={{ flex: 3, alignItems: "flex-end" }}>
        <div style={{ alignSelf: "center" }}>
          <Text type="h3" weight="500">
            Menu Semanal
          </Text>
        </div>
        <Container style={{ flexDirection: "row", flexWrap: "wrap" }}>
          {menus.map((menuItem) => (
            <motion.div
              layout
              key={menuItem.day}
              style={{
                padding: 20,
                margin: 20,
                borderRadius: 10,
                maxWidth: 200,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-around",
                backgroundColor: theme.palette.primary.main,
              }}
            >
              <Button
                context={ComponentContext.INFO}
                key={menuItem.day}
                onClick={(e) => {
                  setOpen(true);
                  e.preventDefault();
                  setDay(menuItem.day);
                }}
              >
                <Text>{menuItem.day}</Text>
              </Button>
              {!isEmptyMenu(menuItem) ? (
                <InProgressIcon
                  style={{ minWidth: 50 }}
                  width={50}
                  height={50}
                />
              ) : (
                <AlertIcon style={{ minWidth: 50 }} width={50} height={50} />
              )}
            </motion.div>
          ))}
        </Container>
        <div style={{ display: "flex" }}>
          <Button
            text="Borrar paciente"
            context={ComponentContext.ERROR}
            loading={loadingDelete}
            onClick={async () => {
              try {
                await deleteUser({
                  variables: {
                    id: user.id,
                  },
                });
              } catch (error) {
                console.log("error trying to delete user", error);
              }
            }}
          />
          <Button
            text="Editar paciente"
            loading={loading}
            onClick={async () => {
              try {
                await updateUser({
                  variables: {
                    id: user.id,
                    email: user.email,
                    displayName: displayName,
                    role: role,
                    menus: menus.map((menu) => ({
                      day: menu.day,
                      meals: menu.meals.map((meal) => ({
                        mealName: meal.mealName,
                        food: meal.food,
                      })),
                    })),
                    username: user.displayName,
                  },
                });
              } catch {}
            }}
          />
        </div>
      </Container>
    </div>
  );
};

export default UserDetail;
