import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeType) => ({
  container: {
    borderRadius: 7,
    margin: (props) => props.margin,
    cursor: (props) => props.cursor,
    "-webkit-tap-highlight-color": "transparent",
    overflow: "hidden",
    backgroundColor: theme.palette.white.main,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 32px 8px 32px",
    flex: 1,
    "&:hover": {
      backgroundColor: theme.palette.white.dark,
    },
  },

  image: {
    width: 50,
    height: 50,
    objectFit: "contain",
    left: -15,
    position: "relative",
  },

  "@media (max-width: 871px)": {
    container: {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "transparent",
      border: "unset",
      boxShadow: "unset",
      padding: 0,
    },

    image: {
      left: 0,
    },
  },
}));

export default useStyles;
