import { useRouteMatch, useHistory } from "react-router";
import React, { FunctionComponent, useRef } from "react";
import { motion, useCycle, Variants } from "framer-motion";

import useStyles from "./styles";
import Button from "components/common/Button";
import { MenuToggle } from "./MenuToggle";
import { ComponentContext } from "utils/types";
import { useDimensions } from "utils/useDimensions";

interface HeaderProps {
  logo: string;
  items: LinkType[];
}

const navVariants: Variants = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 260px 40px)`,
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2,
    },
  }),
  closed: {
    clipPath: "circle(30px at 260px 40px)",
    transition: {
      delay: 0.5,
      type: "spring",
      stiffness: 400,
      damping: 40,
    },
  },
};

const itemsVariants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

const Header: FunctionComponent<HeaderProps> = ({ logo, items }) => {
  const classes = useStyles();
  const { path } = useRouteMatch();
  const containerRef = useRef(null);
  const history = useHistory();
  const { height } = useDimensions(containerRef);
  const [isOpen, cycle] = useCycle(false, true);


  const renderItems = () =>
    items.map((item) => {
      return (
        <Button
          key={item.link}
          text={item.text}
          onClick={() => {
            if(item.action) item.action();
            history.push(item.link)
          }}
          isActive={path === item.link}
          context={item.context || ComponentContext.INFO}
        />
      );
    });

  return (
    <header className={classes.container}>
      <img className={classes.logo} src={logo} alt="logo" />
      <motion.div variants={itemsVariants} className={classes.itemsContainer}>
        {renderItems()}
      </motion.div>
      <motion.nav
        initial={false}
        animate={isOpen ? "open" : "closed"}
        custom={height}
        ref={containerRef}
        className={classes.navContainer}
      >
        <motion.div className={classes.nav} variants={navVariants} />
        <MenuToggle toggle={() => cycle()} />
        <motion.div
          variants={itemsVariants}
          className={`${classes.itemsContainerMobile} ${isOpen && classes.itemsContainerMobileOpen}`}
        >
          {renderItems()}
        </motion.div>
      </motion.nav>
    </header>
  );
};

export default Header;
