import { motion } from "framer-motion";
import React, { FunctionComponent } from "react";

import useStyles from "./styles";
import { ComponentContext } from "utils/types";
import { CircularProgress } from "@material-ui/core";
import theme from "utils/theme";

interface ButtonProps {
  text?: string;
  loading?: boolean;
  alignSelf?: "flex-end" | "flex-start" | "center";
  margin?: number | string;
  disabled?: boolean;
  context?: ComponentContext;
  size?: "small" | "medium" | "large";
  isActive?: boolean;
  width?: number | string;
  Icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const buttonVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const Button: FunctionComponent<ButtonProps> = ({
  text = "",
  margin,
  children,
  isActive = false,
  context = "default",
  onClick,
  size = "small",
  width,
  alignSelf,
  loading,
}) => {
  const classes = useStyles({
    context,
    isActive,
    margin,
    width,
  });
  return (
    <motion.button
      onClick={onClick}
      variants={buttonVariants}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
      disabled={context === "disabled"}
      style={{ alignSelf }}
      className={`${classes.container} ${classes[size]}`}
    >
      {children || text}
      {loading !== undefined && (
        <CircularProgress
          size={15}
          style={{
            color:
              loading !== undefined && !loading
                ? "transparent"
                : context === "default"
                ? theme.palette.primary.main
                : "white",
            marginLeft: 10,
          }}
        />
      )}
    </motion.button>
  );
};

export default Button;
