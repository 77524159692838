import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeType) => ({
  container: {
    background: theme.palette.primary.light,
    display: "flex",
    flexDirection: 'column',
    justifyContent: "center",
    width: '100%',
  },

  row: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20,
    flexWrap: 'wrap',
  },

  button: {

  },

  "@media (max-width: 750px)": {
    container: {
      paddingTop: 43,
      paddingBottom: 43,
    },
  },
}));

export default useStyles;
