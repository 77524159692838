import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeType) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    maxWidth: 700,
    borderRadius: 12,
    padding: 5,
    transition: "all 0.3s ease",
    "&:hover": {
      transition: "all 0.3s ease",
      background: theme.palette.background.main,
    },
  },

  todo: {
    display: "flex",
    flex: 1,
    alignItems: "center",
  },

  delete: {
    display: "flex",
    padding: 0,
    background: "transparent",
    border: 0,
    cursor: "pointer",
    minWidth: 24,
    marginTop: 8,
    minHeight: 24,
    "&:focus": {
      outline: "none",
    },
    "&:active": {
      outline: "none",
    },
  },

  description: {
    fontFamily: "Lato",
    fontSize: 16,
    overflowWrap: "anywhere",
    height: "100%",
    margin: 0,
    color: theme.palette.black.main,
    marginRight: 5,
    flex: 1,
    transition: "all ease 0.1s",
    cursor: "pointer",
  },

  form: {
    flex: 1,
  },

  inputDescription: {
    border: 0,
    width: "95%",
    fontSize: 16,
    padding: 0,
    marginRight: 5,
    flex: 1,
    height: "100%",
    backgroundColor: "transparent",
    fontFamily: "Lato",
    "&:focus": {
      outline: "none",
    },
  },

  completed: {
    color: theme.palette.black.dark,
    textDecorationLine: "line-through;",
    transition: "all ease 0.1s",
  },
}));

export default useStyles;
